import { ReactNode, MouseEvent, ButtonHTMLAttributes, HTMLProps } from 'react';
import { Locale } from 'ui/config/locales';
import type { LinkField } from '@prismicio/client';

export interface ButtonProps
  extends Omit<HTMLProps<HTMLAnchorElement | HTMLButtonElement>, 'onClick' | 'size' | 'type'> {
  field?: LinkField;
  href?: string;
  text?: string;
  appearance?: ButtonAppearance;
  color?: ButtonColor;
  outline?: boolean;
  size?: ButtonSize;
  className?: string;
  new_tab?: boolean;
  rounded?: boolean;
  marginTop?: number;
  iconType?: string;
  arrowIcon?: boolean;
  loading?: boolean;
  cta?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  prefetch?: boolean;
  stretch?: boolean;
  invert?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  removeLinkAnimation?: boolean;
  icon?: ReactNode;
  onMouseEnter?: () => void;
  locale?: Locale;
  dataTestid?: string;
  postIcon?: ReactNode;
  baseBtn?: 'primary' | 'secondary' | 'hero';
}

export enum ButtonAppearance {
  InvisibleLink = 'invisible-link',
  Link = 'link',
  LinkGray = 'link-gray',
  LinkGraySmall = 'link-gray-sm',
  Fluid = 'fluid',
  LinkWhite = 'link-white',
  LinkWhiteV2 = 'link-white-v2',
}

export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Transparent = 'transparent',
}
export enum ButtonSize {
  Medium = 'md',
  Small = 'sm',
  ExtraSmall = 'xs',
}
