import { isNonProdEnvironment } from 'ui/utils/helpers/env-helper';
import { getPreviousMonthString } from 'ui/utils/getPreviousMonthString';

const isNonProd = isNonProdEnvironment();

export const constants = {
  BLOG_URL_PART: '/blog',
  SELF_SERVE_URL: isNonProd ? 'https://uat-self-serve.turing.com' : 'https://self-serve.turing.com/sign-in',
  CDN_URL: 'https://d2mk45aasx86xg.cloudfront.net',
  MATCHING_BASE_URL: 'https://matching.turing.com',
  MATCHING_SERVICE_ACCOUNT_TOKEN: '5|a8KReeXVMeh1JWZboFDK5Jm9JlDpwZoK',
  DEVELOPER_TOTAL_RATINGS: '5',
  COVER_IMAGE_URL: '/img/turing_hire_top_remote_developers.png',
  LINK_SHORTENER: 'https://linkshortener.turing.com/t',
  DEFAULT_EMPLOYEE_COUNT: 250,
  DEFAULT_INDUSTRY: 'Software',
  CF_GET_COUNTRY_API: isNonProd
    ? 'https://staging-customers.turing.com/api/customer-flow-ip-details/get-country'
    : 'https://customers.turing.com/api_/customer-flow-ip-details/get-country',
  CF_GET_EMAIL_VALIDATION_API: isNonProd
    ? 'https://staging-customers.turing.com/api/customer-flow-sessions/validate/email'
    : 'https://customers.turing.com/api_/customer-flow-sessions/validate/email',
  CAREERS_URL: 'https://careers.turing.com/',
  COMMUNITY_URL: 'https://community.turing.com/',
  CUSTOMER_URL: isNonProd ? `https://staging-customers.turing.com` : `https://customers.turing.com`,
  CUSTOMER_FUNNEL_URL: isNonProd ? `https://staging-customers.turing.com/hire/` : `https://customers.turing.com/hire/`,
  CUSTOMER_TEAMS_FUNNEL_URL: isNonProd
    ? `https://staging-customers.turing.com/services/company/`
    : `https://customers.turing.com/services/company/`,
  CUSTOMER_SERVICES_FUNNEL_URL: isNonProd
    ? `https://staging-customers.turing.com/services/company/`
    : `https://customers.turing.com/services/company/`,
  DEVELOPER_FUNNEL_URL: isNonProd ? 'https://staging-developers.turing.com' : 'https://developers.turing.com',
  HELP_CENTER_URL: 'https://help.turing.com',
  CUSTOMER_FAQ_URL: 'https://help.turing.com/im-a-customer',
  DEVELOPER_FAQ_URL: 'https://help.turing.com/im-a-developer',
  DEVELOPER_BLOG_URL: 'https://www.turing.com/blog/',
  YOUTUBE_VIDEOS: {
    YT_VIDEO_CACHE_KEY: `YT_SCHEMA`,
    TTL: 60 * 60 * 24,
    YT_CONTENT_PARTS: ['snippet', 'contentDetails', 'statistics'].join(','),
  },
  CHIPS_TYPE: {
    HIRE: 'HIRE',
    JOB: 'JOB',
    JD: 'JD',
    INTERVIEW_QUESTIONS: 'INTERVIEW_QUESTIONS',
  },
  CACHE_KEYS: {
    LIVE_JOBS_DETAIL_STATIC_DATA: 'LIVE_JOBS_DETAIL_STATIC_DATA',
    LIVE_JOBS_DETAIL_BLOGS: 'LIVE_JOBS_DETAIL_BLOGS',
    ALL_SKILLS_FROM_MATCHING: 'ALL_SKILLS_FROM_MATCHING',
  },
  SITEMAP_SLUG: {
    JOBS: 'jobs',
    HIRE_DEVELOPERS: 'hire-developers',
    LOCATION: 'location',
    INTERVIEW_QUESTIONS: 'interview-questions',
    JOB_DESCRIPTION: 'job-description',
    TOP_SOFTWARE_JOBS: 'top-software-jobs',
    RESUME: 'resume',
    LATAM: 'latam',
    REVIEW: 'review',
    HIRE_TEAMS: 'teams',
    SERVICES: 'services',
  },
  SIGNUP_COUNT: '2 million+',
  DEVELOPER_CTA_TEXT: 'Apply for Jobs',
  CUSTOMER_CTA_TEXT: 'Hire Developers',
  REMOTE_WORK_GUIDE: {
    HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit',
    HUBSPOT_PORTAL: 8835306,
    HUBSPOT_FORM: 'b9f1bcad-ef5b-4cda-b526-9f3df6d25107',
    SLIDES_URL: 'https://www.canva.com/design/DAE4inAeJTc/view?embed',
  },
  BREAKPOINTS: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
  },
  DEVELOPER_RATING_MONTH: getPreviousMonthString(),
  CLOUD_PAGE_CALENDLY_ROUTING_FORM_URL: 'https://calendly.com/d/g5p-nxm-q5p',
  TALK_TO_AN_EXPERT: 'talk-to-an-expert',
  LI_PROMOTED_JOBS_ROUTE: '/remote-developer-jobs/j/li-promoted/[jobIdSlug]',
};
