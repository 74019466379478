import dynamic from 'next/dynamic';
import type { FC } from 'react';

const svgs = {
  Collaboration: dynamic(() => import('./Collaboration')),
  BuildingTeams: dynamic(() => import('./BuildingTeams')),
  RemoteFirst: dynamic(() => import('./RemoteFirst')),
  Success: dynamic(() => import('./Success')),
  IntelligentMatching: dynamic(() => import('./IntelligentMatching')),
  MatchingWithAI: dynamic(() => import('./MatchingWithAI')),
  ManagedDevelopmentTeam: dynamic(() => import('./ManagedDevelopmentTeam')),
  CareerGrowth: dynamic(() => import('./CareerGrowth')),
  Pepsi: dynamic(() => import('./Pepsi')),
  Dell: dynamic(() => import('./Dell')),
  Reddit: dynamic(() => import('./Reddit')),
  Volvo: dynamic(() => import('./Volvo')),
  Disney: dynamic(() => import('./Disney')),
  Python: dynamic(() => import('./Python')),
  MachineLearning: dynamic(() => import('./MachineLearning')),
  DotNet: dynamic(() => import('./DotNet')),
  Photoshop: dynamic(() => import('./Photoshop')),
  AWS: dynamic(() => import('./AWS')),
  Angular: dynamic(() => import('./Angular')),
  Redux: dynamic(() => import('./Redux')),
  Javascript: dynamic(() => import('./Javascript')),
  Golang: dynamic(() => import('./Golang')),
  RubyRails: dynamic(() => import('./RubyRails')),
  TypeScript: dynamic(() => import('./TypeScript')),
  Android: dynamic(() => import('./Android')),
  DataEngineer: dynamic(() => import('./DataEngineer')),
  Php: dynamic(() => import('./Php')),
  AmazonKinesis: dynamic(() => import('./AmazonKinesis')),
  Blockchain: dynamic(() => import('./Blockchain')),
  AndroidStudio: dynamic(() => import('./AndroidStudio')),
  Html: dynamic(() => import('./Html')),
  GraphQL: dynamic(() => import('./GraphQL')),
  Java: dynamic(() => import('./Java')),
  CSharp: dynamic(() => import('./CSharp')),
  CLanguage: dynamic(() => import('./CLanguage')),
  Css: dynamic(() => import('./Css')),
  EmberJs: dynamic(() => import('./EmberJs')),
  Django: dynamic(() => import('./Django')),
  NodeJs: dynamic(() => import('./NodeJs')),
  Flutter: dynamic(() => import('./Flutter')),
  React: dynamic(() => import('./React')),
  Adonisjs: dynamic(() => import('./Adonisjs')),
  Sql: dynamic(() => import('./Sql')),
  Airflow: dynamic(() => import('./Airflow')),
  IosSwift: dynamic(() => import('./IosSwift')),
  CiCd: dynamic(() => import('./CiCd')),
  Rowing: dynamic(() => import('./Rowing')),
  FastCompanyGray: dynamic(() => import('./FastCompanyGray')),
  ForbesGray: dynamic(() => import('./ForbesGray')),
  TechCrunchGray: dynamic(() => import('./TechCrunchGray')),
  TheInformationGray: dynamic(() => import('./TheInformationGray')),
  WorkingFromHome: dynamic(() => import('./WorkingFromHome')),
  RoundTable: dynamic(() => import('./RoundTable')),
  Kubernetes: dynamic(() => import('./Kubernetes')),
  Cloud: dynamic(() => import('./Cloud')),
  DataScientist: dynamic(() => import('./DataScientist')),
  MongoDB: dynamic(() => import('./MongoDB')),
  ReactNative: dynamic(() => import('./ReactNative')),
  Laravel: dynamic(() => import('./Laravel')),
  Jenkins: dynamic(() => import('./Jenkins')),
  Kotlin: dynamic(() => import('./Kotlin')),
  Ruby: dynamic(() => import('./Ruby')),
  Magento: dynamic(() => import('./Magento')),
  WooCommerce: dynamic(() => import('./WooCommerce')),
  Spring: dynamic(() => import('./Spring')),
  PostgreSql: dynamic(() => import('./PostgreSql')),
  Xamarin: dynamic(() => import('./Xamarin')),
  Json: dynamic(() => import('./Json')),
  ApacheCordova: dynamic(() => import('./ApacheCordova')),
  DevOps: dynamic(() => import('./DevOps')),
  FullStack: dynamic(() => import('./FullStack')),
  Hadoop: dynamic(() => import('./Hadoop')),
  NextJS: dynamic(() => import('./NextJS')),
  Frontend: dynamic(() => import('./Frontend')),
  VueJS: dynamic(() => import('./VueJS')),
  Solidity: dynamic(() => import('./Solidity')),
  Backend: dynamic(() => import('./Backend')),
  WordPress: dynamic(() => import('./WordPress')),
  SAP: dynamic(() => import('./SAP')),
  ArtificialIntelligence: dynamic(() => import('./ArtificialIntelligence')),
  PowerBI: dynamic(() => import('./PowerBI')),
  AppleIOS: dynamic(() => import('./AppleIOS')),
  Scale: dynamic(() => import('./Scale')),
  HireTopDeveloper: dynamic(() => import('./HireTopDeveloper')),
  FindDevelopers: dynamic(() => import('./FindDevelopers')),
  GoogleCloudPartner: dynamic(() => import('./GoogleCloudPartner')),
  AzurePartner: dynamic(() => import('./AzurePartner')),
  AwsPartner: dynamic(() => import('./AwsPartner')),
  CharacterAi: dynamic(() => import('./CharacterAi')),
  Imbue: dynamic(() => import('./Imbue')),
  OpenAi: dynamic(() => import('./OpenAi')),
  Anthropic: dynamic(() => import('./Anthropic')),
  AnthropicBlack: dynamic(() => import('./logos/black/Anthropic')),
  OpenAiBlack: dynamic(() => import('./logos/black/OpenAi')),
  DellBlack: dynamic(() => import('./logos/black/Dell')),
  RedditBlack: dynamic(() => import('./logos/black/Reddit')),
  VolvoBlack: dynamic(() => import('./logos/black/Volvo')),
  DisneyBlack: dynamic(() => import('./logos/black/Disney')),
  AnthropicWhite: dynamic(() => import('./logos/white/Anthropic')),
  OpenAiWhite: dynamic(() => import('./logos/white/OpenAi')),
  DellWhite: dynamic(() => import('./logos/white/Dell')),
  PepsiWhite: dynamic(() => import('./logos/white/Pepsi')),
  VolvoWhite: dynamic(() => import('./logos/white/Volvo')),
  DisneyWhite: dynamic(() => import('./logos/white/Disney')),
  AiStrategyBlue: dynamic(() => import('./AiStrategyBlue')),
  DataProtectionBlue: dynamic(() => import('./DataProtectionBlue')),
  EnterpriseLLMBlue: dynamic(() => import('./EnterpriseLLMBlue')),
  CloseModal: dynamic(() => import('./CloseModal')),
  CustomEngineering: dynamic(() => import('./CustomEngineering')),
  FoundationsModels_LLM: dynamic(() => import('./FoundationsModels_LLM')),
  TechnicalTeam: dynamic(() => import('./team/TechnicalTeam')),
  TechnicalTeamBlue: dynamic(() => import('./team/TechnicalTeamBlue')),
  ArrowForward: dynamic(() => import('./ArrowForward')),
  LinkForwardArrow: dynamic(() => import('./LinkForwardArrow')),
  LinkForwardArrowBlack: dynamic(() => import('./LinkForwardArrowBlack')),
  ChevronRight: dynamic(() => import('./ChevronRight')),
  TuringWhiteLogo: dynamic(() => import('./TuringWhiteLogo')),
  DayWeeksToday: dynamic(() => import('./DayWeeksToday')),
  FiftyPlusHours: dynamic(() => import('./FiftyPlusHours')),
  FreeTrial: dynamic(() => import('./FreeTrial')),
  Percentage: dynamic(() => import('./Percentage')),
  SkillsTech: dynamic(() => import('./SkillsTech')),
  CodingDebugStatus: dynamic(() => import('./CodingDebugStatus')),
  RapidPrototyping: dynamic(() => import('./RapidPrototyping')),
  RealTimePredictiveAnalytics: dynamic(() => import('./RealTimePredictiveAnalytics')),
  ToolProficiencyApplication: dynamic(() => import('./ToolProficiencyApplication')),
  ModelTraining: dynamic(() => import('./ModelTraining')),
  AICodeAssistanceFull: dynamic(() => import('./AICodeAssistanceFull')),
  AIOptimizedResourceAllocation: dynamic(() => import('./AIOptimizedResourceAllocation')),
  ModelAssessmentAndStrategy: dynamic(() => import('./ModelAssessmentAndStrategy')),
  TrainingTaskDesignAndExecution: dynamic(() => import('./TrainingTaskDesignAndExecution')),
  ScaleOnDemand: dynamic(() => import('./ScaleOnDemand')),
  AI_ML: dynamic(() => import('./AI_ML')),
  GenAI: dynamic(() => import('./GenAI')),
  CustomEngineeringBlue: dynamic(() => import('./CustomEngineeringBlue')),
  ExpandMore: dynamic(() => import('./ExpandMore')),
  ExpandLess: dynamic(() => import('./ExpandLess')),
  CircleArrowForward: dynamic(() => import('./arrows/CircleArrowForward')),
  CircleArrowBack: dynamic(() => import('./arrows/CircleArrowBack')),
  SearchIcon: dynamic(() => import('./SearchIcon')),
  ROI_AI: dynamic(() => import('./ROI_AI')),
  TechProps_Teams: dynamic(() => import('./TechProps_Teams')),
  DataManagement: dynamic(() => import('./DataManagement')),
  ML_OPS_PROCESS: dynamic(() => import('./ML_OPS_PROCESS')),
  AppMaintenance: dynamic(() => import('./AppMaintenance')),
  CloudStrategy: dynamic(() => import('./CloudStrategy')),
  CloudOptimization: dynamic(() => import('./CloudOptimization')),
  CloudModernization: dynamic(() => import('./CloudModernization')),
  SecureCompliant: dynamic(() => import('./SecureCompliant')),
  CloudDevops: dynamic(() => import('./CloudDevops')),
  ReasoningProblemSolving: dynamic(() => import('./ReasoningProblemSolving')),
  ScientificMathematicalApplication: dynamic(() => import('./ScientificMathematicalApplication')),
  DataAnalysisStats: dynamic(() => import('./DataAnalysisStats')),
  AccountBox: dynamic(() => import('./AccountBox')),
  AppManagement: dynamic(() => import('./AppManagement')),
  CloudLegacyTransition: dynamic(() => import('./CloudLegacyTransition')),
  Strategy: dynamic(() => import('./Strategy')),
  Lock: dynamic(() => import('./Lock')),
  Performance: dynamic(() => import('./Performance')),
  TechProps_Teams_blue: dynamic(() => import('./TechProps_Teams_blue')),
  TechnicalSkillsBlue: dynamic(() => import('./TechnicalSkillsBlue')),
  TechnicalProfessionalsBlue: dynamic(() => import('./TechnicalProfessionalsBlue')),
  FreeTrialBlue: dynamic(() => import('./FreeTrialBlue')),
  ScaleOnDemandBlue: dynamic(() => import('./ScaleOnDemandBlue')),
  DiverseTechEngagementSource: dynamic(() => import('./DiverseTechEngagementSource')),
  OverviewStoriesIcon: dynamic(() => import('./OverviewStoriesIcon')),
  OverviewNewsReleasesIcon: dynamic(() => import('./OverviewNewsReleasesIcon')),
  OverviewAboutUsIcon: dynamic(() => import('./OverviewAboutUsIcon')),
  MailIcon: dynamic(() => import('./MailIcon')),
  DownloadIcon: dynamic(() => import('./DownloadIcon')),
  FilterIcon: dynamic(() => import('./FilterIcon')),
  CloseIcon: dynamic(() => import('./CloseIcon')),
  CloseCircleIcon: dynamic(() => import('./CloseCircleIcon')),
  Link: dynamic(() => import('./Link')),
  Twitter: dynamic(() => import('./Twitter')),
  LinkedIn: dynamic(() => import('./LinkedIn')),
  Document: dynamic(() => import('./Document')),
  AIGen: dynamic(() => import('./AIGen')),
  Facebook: dynamic(() => import('./Facebook')),
  Share: dynamic(() => import('./Share')),
  EnterKey: dynamic(() => import('./EnterKey')),
  Building: dynamic(() => import('./Building')),
  CircleCheckMark: dynamic(() => import('./CircleCheckMark')),
  PersonaArcDesktopLeft: dynamic(() => import('./PersonaArcDesktopLeft')),
  PersonaArcDesktopRight: dynamic(() => import('./PersonaArcDesktopRight')),
};

interface SVGLibraryProps {
  name?: keyof typeof svgs;
  fill?: string;
  className?: string;
}

export const SVGLibrary: FC<SVGLibraryProps> = props => {
  if (!props.name || !svgs[props.name]) {
    return null;
  }

  const Svg = svgs[props.name];
  return <Svg fill={props.fill} className={props.className} />;
};
