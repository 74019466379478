import Script from 'next/script';
import { FC, useState } from 'react';
import { EnvironmentDomains } from 'ui/config/environment';
import { GTM_SCRIPT } from 'ui/utils/external-scripts/GAScripts';

export const HeadScripts: FC = () => {

  if (process.env.NEXT_PUBLIC_SUBDOMAIN !== EnvironmentDomains.Prod) {
    return null;
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="GTM"
        dangerouslySetInnerHTML={{
          __html: GTM_SCRIPT,
        }}
      />
    </>
  );
};
