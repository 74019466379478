import classNames from 'classnames';
import Image from 'next/image';
import { SearchResultType } from '../../../../apps/web/service/elasticsearch';
import { CustomLink } from '../CustomLink';
import styles from './SearchResult.module.scss';
import { decodeHtmlEntities } from 'ui/utils/decodeHtml';

type SearchResultProps = {
  result: SearchResultType;
  variant?: 'dark' | 'light';
}

export default function SearchResult({ result, variant = 'dark' }: SearchResultProps) {
  let image = result.og_image?.raw;

  if (result.og_image?.raw === 'https://www.turing.com/img/og_image.png') {
    image = result.primary_image?.raw;
  }

  const publicationDate = new Date(result.article_date?.raw);

  return (
    <CustomLink href={result.url.raw}>
      <a className={classNames(styles.root, styles[`root-${variant}`])}>
        <div className={styles.body}>
          {result.content_type?.raw && <div className={styles.chip}>{result.content_type?.raw}</div>}
          <div className={styles.title} dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(result.title?.snippet) }} />
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: result.body_content?.snippet.replace(/^[\s!@#$%^&*()_+\-=\[\]{};':"\\|,.\/?]+/, '').trim() }} />
          {result.article_date?.raw && variant === 'dark' && <div className={styles.date}>{publicationDate.toDateString().split(' ')[1]} {publicationDate.getDate()}, {publicationDate.getFullYear()}</div>}
        </div>
        {image && (
          <div>
            <div className={styles.image}>
              <Image src={image} fill alt="" />
            </div>
          </div>
        )}
      </a>
    </CustomLink>
  )
}
