import { getFromLocalStorage, setToLocalStorage } from 'ui/utils/helpers/session-helper';
import { constants } from 'ui/utils/constants';
import { createClient } from 'prismic/src/prismicio';

const getCountry = async () => {
  try {
    let countryName = getFromLocalStorage('user_country');

    if (!countryName) {
      try {
        const response = await fetch(constants.CF_GET_COUNTRY_API);
        const res = await response.json();
        countryName = res.country.toLowerCase();
      } catch (err) {
        countryName = 'united states';
      }
      setToLocalStorage('user_country', countryName);
    }

    return countryName;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    setToLocalStorage('user_country', 'united states');
    return 'united states';
  }
};

const getHeaderData = async previewData => {
  const client = createClient({ previewData });
  const headerEN = await client.getByType('header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
      'resources.title',
      'resources.description',
      'resources.image',
    ],
    lang: 'en-us',
  });
  const headerData: any = headerEN.results[0].data;

  return headerData;
};
const getHeaderDataES = async previewData => {
  const client = createClient({ previewData });
  const headerES = await client.getByType('header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
      'resources.title',
      'resources.description',
      'resources.image',
    ],
    lang: 'es-ar',
  });
  const headerData: any = headerES.results[0].data;

  return headerData;
};
const getHeaderDataPT = async previewData => {
  const client = createClient({ previewData });
  const headerPT = await client.getByType('header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
      'resources.title',
      'resources.description',
      'resources.image',
    ],
    lang: 'pt-br',
  });
  const headerData: any = headerPT.results[0].data;

  return headerData;
};

const getModalGetStartedData = async previewData => {
  const client = createClient({ previewData });
  const getStarted = await client.getByType('get_started', { lang: 'en-us' });
  const getStartedData: any = getStarted.results[0].data;
  return getStartedData;
};

const getModalGetStartedDataES = async previewData => {
  const client = createClient({ previewData });
  const getStarted = await client.getByType('get_started', { lang: 'es-ar' });
  const getStartedData: any = getStarted.results[0].data;
  return getStartedData;
};

const getModalGetStartedDataPT = async previewData => {
  const client = createClient({ previewData });
  const getStarted = await client.getByType('get_started', { lang: 'pt-br' });
  const getStartedData: any = getStarted.results[0].data;
  return getStartedData;
};

const getModalSearchData = async previewData => {
  const client = createClient({ previewData });
  const search = await client.getByType('search_modal', { lang: 'en-us' });
  const searchData: any = search.results[0].data;
  return searchData;
};

const getSubHeaderData = async previewData => {
  const client = createClient({ previewData });
  const headerEN = await client.getByType('sub_header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
    ],
    lang: 'en-us',
  });
  const headerData: any = headerEN.results[0].data;

  return headerData;
};
const getSubHeaderDataES = async previewData => {
  const client = createClient({ previewData });
  const headerEN = await client.getByType('sub_header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
    ],
    lang: 'es-ar',
  });
  const headerData: any = headerEN.results[0].data;

  return headerData;
};
const getSubHeaderDataPT = async previewData => {
  const client = createClient({ previewData });
  const headerEN = await client.getByType('sub_header', {
    fetchLinks: [
      'header_dropdown_links.navigation_links',
      'navigation_list.navigation_items',
    ],
    lang: 'pt-br',
  });
  const headerData: any = headerEN.results[0].data;

  return headerData;
};

export const HeaderService = {
  getCountry,
  getHeaderData,
  getHeaderDataES,
  getHeaderDataPT,
  getModalGetStartedData,
  getModalGetStartedDataES,
  getModalGetStartedDataPT,
  getModalSearchData,
  getSubHeaderData,
  getSubHeaderDataES,
  getSubHeaderDataPT,

};
