import {
  Experiment,
  FeatureDefinition,
  Result,
} from '@growthbook/growthbook-react';
import {
  getFromCookie,
  getFromSessionStorage,
  setToSessionStorage,
} from 'ui/utils/helpers/session-helper';
import { v4 } from 'uuid';
import { ImpervaUserInfo } from 'ui/types/imperva-user-info';
import { TOKEN_COOKIE_NAME } from '../config';
import { logExperiment } from '../logging';

export const getUserId = () => {
  const id = localStorage.getItem('gb-id') || v4();
  localStorage.setItem('gb-id', id);
  return id;
};

export const trackingCallbackHandler = (
  experiment: Experiment<any>,
  result: Result<any>,
) => {
  const visitorToken = getFromCookie(TOKEN_COOKIE_NAME);
  const payload = {
    feature: result.featureId,
    experimentKey: experiment.key,
    variationId: result.variationId,
    value: result.value,
  };
  if (visitorToken) {
    // Giving turingLogger library to be ready to log
    setTimeout(() => {
      logExperiment(payload);
    }, 1000);
  } else {
    addExperimentPayloadToSessionStorage(payload);
  }
};

/**
 * Adding experiment log payload to session storage, if the visitor token is not available at the time of experiment execution.
 * Futher the log payload will be picked from session storage, when the visitor token is available and will be logged to BigQuery
 * @param {*} payload
 */
const addExperimentPayloadToSessionStorage = payload => {
  let payloads = getFromSessionStorage('ef-payload');
  if (payloads && payloads instanceof Array) {
    payloads.push(payload);
  } else {
    payloads = [payload];
  }

  setToSessionStorage('ef-payload', payloads);
};

/**
 * Picking the experiment log payload from session storage and adding them to BigQuery
 */
export const logClientExperimentsIfAny = () => {
  /** @type {any[]} */
  const payloads = getFromSessionStorage('ef-payload');
  const token = getFromCookie(TOKEN_COOKIE_NAME);
  if (payloads && payloads instanceof Array && token) {
    payloads.forEach(payload => {
      logExperiment(payload);
    });
    setToSessionStorage('ef-payload', []);
  }
};

export const getFeatures = async (): Promise<
  [Record<string, FeatureDefinition<any>>, ImpervaUserInfo]
> => {
  const start = new Date();
  const api = await fetch('/api/growthbook');
  const userinfoString = api.headers.get('Impervauserinfo');
  let impervaUserInfo: ImpervaUserInfo = null;
  if (userinfoString) {
    impervaUserInfo = JSON.parse(userinfoString);
  }

  const response = await api.json();
  const duration = new Date().getTime() - start.getTime();

  // eslint-disable-next-line no-console
  console.info('Growthbook API:', `${duration}ms`);

  return [response.features, impervaUserInfo];
};
