export enum Locale {
  English = 'en',
  Spanish = 'es',
  Portuguese = 'pt',
}

export const Locales: Locale[] = [Locale.English, Locale.Spanish, Locale.Portuguese];

export const LocaleEnabledPathDictionary = {
  jobs: '/jobs',
  liveJobsMaster: '/remote-developer-jobs',
  review: '/review',
  press: '/press',
  company: '/company',

  liveJobsDetail: '/remote-developer-jobs/j/[jobIdSlug]',
  promotedLinkedinLiveJobsDetail: '/remote-developer-jobs/j/li-promoted/[jobIdSlug]',
  jobSpecific: '/jobs/[id]',
  liveJobsFilter: '/remote-developer-jobs/[id]',
  boundaryless: '/boundaryless',
  interviewQuestions: '/interview-questions/[id]',
};

export const LocaleEnabledPaths = Object.values(LocaleEnabledPathDictionary);

export enum PrismicLocales {
  en = 'en-us',
  pt = 'pt-br',
  es = 'es-ar',
}

export enum AppLocales {
  'en-us' = Locale.English,
  'pt-br' = Locale.Portuguese,
  'es-ar' = Locale.Spanish,
}
