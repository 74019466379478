import { FeatureConfigModel } from './interfaces';

export const FeatureConfig: { [key: string]: FeatureConfigModel } = {
  // '/services': {
  //   feature: 'services',
  //   rewrite: {
  //     OFF: ['/services', '/404'],
  //   },
  // },
  // '/services/[slug]': {
  //   feature: 'services',
  //   rewrite: {
  //     OFF: [url => url.pathname, '/404'],
  //   },
  // },
};

export const RESULT_COOKIE_NAME = 'ef_result';
export const PAGE_CACHE_COOKIE_NAME = 'page-variant';
export const TOKEN_COOKIE_NAME = 'visitor_token';
