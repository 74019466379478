import { REFERRER_SESSION_KEY } from '../helpers/session-helper';

export let eventQueue = [];
const QUEUE_LIMIT = 5; // Example limit

export const resetEventQueue = data => {
  eventQueue = [...data];
};
function addToQueue(event) {
  eventQueue.push(event);
  if (eventQueue.length >= QUEUE_LIMIT) {
    flushQueue();
  }
}
export async function flushQueue() {
  if (eventQueue.length > 0) {
    const dataToSend = [...eventQueue];
    eventQueue = []; // Reset the queue
    if (dataToSend.length > 0) {
      try {
        fetch('/api/analytics', {
          method: 'POST',
          body: JSON.stringify(dataToSend),
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to send events:', error);
        // Add events back to the queue if the request fails
        eventQueue = dataToSend.concat(eventQueue);
      }
    }
  }
}

export const LogPageView = (pathname, userId) => {
  if (!doesPathMatch(pathname)) {
    return;
  }
  addToQueue({
    userId,
    properties: {
      pathname,
      referrer: sessionStorage.getItem(REFERRER_SESSION_KEY),
      url: document.URL,
      title: document.title,
      search: new URL(document.URL).search,
    },
    eventType: 'page_view',
  });
};
/**
 * @param pathname
 * @param userId
 * @param formData
 * @returns
 */
export const LogFormFills = (pathname, userId, formData) => {
  if (!doesPathMatch(pathname)) {
    return;
  }
  if (
    (formData.name || formData.fullname || formData.email) &&
    eventQueue.findIndex(d => {
      let isFound = false;
      if (d?.traits?.email) {
        isFound = isFound || d?.traits?.email === formData.email;
      }
      if (d?.traits?.name) {
        isFound = isFound || d?.traits?.name === formData.name;
      }
      if (d?.traits?.fullname) {
        isFound = isFound || d?.traits?.fullname === formData.fullname;
      }
      if (d?.traits?.firstname) {
        isFound = isFound || d?.traits?.firstname === formData.firstname;
      }
      return isFound;
    }) === -1
  ) {
    addToQueue({
      userId,
      traits: {
        email: formData.email,
        name: formData.name || formData.fullname || formData.firstname,
      },
      eventType: 'identify',
    });
  }
  addToQueue({
    userId,
    properties: {
      pathname,
      referrer: sessionStorage.getItem(REFERRER_SESSION_KEY),
      formData,
      url: document.URL,
      title: document.title,
      search: new URL(document.URL).search,
    },
    eventType: 'form_fills',
  });
};
// only path that needs tracking
const regexps = [
  /\/services\/?.*/,
  /\/blog\/?.*/,
  /\/resources\/?.*/,
  /^\/hire-?.*/, // captures /hire/[slug], /hire-developers, /hire-engineers, and /hire-professionals
  /\/how-to-hire-remote-software-developers\/?/,
  /^\/$/,
  /\/company\/?/,
  /\/get-started\/?/,
  /\/contact-us\/?/,
];

function doesPathMatch(path) {
  return regexps.some(regexp => regexp.test(path));
}
