import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Youtube,
} from 'ui/svgs/Social';

export const socialLinks = [
  {
    displayName: 'Facebook',
    href: 'https://www.facebook.com/turingcom',
    icon: <Facebook />,
  },
  {
    displayName: 'Twitter',
    href: 'https://twitter.com/turingcom',
    icon: <Twitter size={22} />,
  },
  {
    displayName: 'LinkedIn',
    href: 'https://www.linkedin.com/company/turingcom',
    icon: <LinkedIn />,
  },
  {
    displayName: 'Instagram',
    href: 'https://www.instagram.com/turingcom',
    icon: <Instagram />,
  },
  {
    displayName: 'Youtube',
    href: 'https://www.youtube.com/channel/UCLfxdtB3H0JdnniBnd4DoNA',
    icon: <Youtube />,
  },
];
