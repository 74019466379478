import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ErrorComponent } from './ErrorComponent';

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    /**
     * TODO: Refactor the below code this is temporary
     * 
     * This will reload the page whenever there is an error related to NExtJs case
     * 
     */
    if (
      error instanceof TypeError &&
      (error.message.includes("undefined is not an object (evaluating 't.data')") ||
        error.message.includes("Cannot destructure property 'data' of 'a' as it is undefined") ||
        error.message.includes("Cannot read properties of undefined (reading"))
    ) {
      document.location.reload();
    }

    // Capture error boundary error manually to sentry
    Sentry.withScope(function (scope) {
      scope.setTag('error-boundary', 'YES');
      // will be tagged with error-boundary="YES"
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}
