import { HttpService } from "ui/services/http";

export async function getSearchSuggestions(query: string, limit: number = 5): Promise<string[]> {
  try {
    const { data } = await HttpService.post(`${process.env.NEXT_PUBLIC_ELASTICSEARCH_URL}/query_suggestion`, {
      query,
      size: limit,
    }, {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_ELASTICSEARCH_API_KEY}`,
      }
    })

    return data.results.documents.map((result: { suggestion: string }) => result.suggestion)
  } catch(e) {
    return [];
  }
};

export type SearchResultType = {
  id: string;
  body_content: {
    snippet: string;
  };
  title: {
    raw: string;
    snippet: string;
  };
  url: {
    raw: string;
  };
  content_type: {
    raw: string;
  };
  og_image: {
    raw: string;
  };
  primary_image: {
    raw: string;
  };
  article_date: {
    raw: string;
  }
}

export type SearchResponse = {
  meta: {
    page: {
      current: number;
      total_pages: number;
      total_results: number;
      size: number;
    },
  },
  results: SearchResultType[],
}

type SearchOptions = {
  page?: number;
  size?: number;
  filters?: {
    all?: Array<{
      content_type?: string[];
      gtm_category?: string[];
    }>;
  };
};

export async function getSearchResults(
  query: string,
  { page = 1, size = 10, filters }: SearchOptions = { page: 1, size: 10 },
): Promise<SearchResponse> {
  try {
    const { data } = await HttpService.post(`${process.env.NEXT_PUBLIC_ELASTICSEARCH_URL}/search`, {
      query,
      filters,
      page: {
        size,
        current: page,
      },
      result_fields: {
        title: {
          raw: {},
          snippet: {
            size: 100,
            fallback: true,
          }
        },
        body_content: {
          snippet: {
            size: 100,
            fallback: true,
          }
        },
        url: {
          raw: {},
        },
        content_type: {
          raw: {},
        },
        og_image: {
          raw: {},
        },
        primary_image: {
          raw: {}
        },
        article_date: {
          raw: {}
        },
      },
    }, {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_ELASTICSEARCH_API_KEY}`,
      }
    })

    return data;
  } catch(e) {
    return {
      meta: {
        page: {
          current: 1,
          total_pages: 1,
          total_results: 0,
          size: 10,
        },
      },
      results: [],
    };
  }
};

export async function getSearchContentTypes(): Promise<{ value: string }[]> {
  try {
    const { data } = await HttpService.post(`${process.env.NEXT_PUBLIC_ELASTICSEARCH_URL}/search`, {
      query: '',
      page: {
        size: 1,
        current: 1,
      },
      result_fields: {
        title: {
          snippet: {
            size: 20,
            fallback: true,
          }
        },
      },
      facets: {
        content_type: {
          type: 'value',
          size: 10,
        },
      },
    }, {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_ELASTICSEARCH_API_KEY}`,
      }
    })

    return data.facets.content_type[0].data;
  } catch(e) {
    return [];
  }
};
