import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Headline } from 'ui/components/Headline';
import { SectionProps } from './section.interface';
import styles from './section.module.scss';

export const Section: FC<PropsWithChildren<SectionProps>> = props => {
  const {
    bg = 'white',
    textAlign,
    title,
    titleClassName,
    watchVideoBtn,
    headlineMargin = 'md',
    subTitle = '',
    headlineColumnOneSize,
    textColor,
    className,
    pad = 'md',
    topPad,
    botPad,
    headlineSecondColumn,
    containerSize = 'normal',
    containerClass,
    headlineTag,
    headlineSize = 'md',
    headlineType = 'normal',
    children,
    isSubtitleHTML,
    overflowHidden = false,
    subTitleClassName,
    noContainerPadding = false,
    applySubtitleHTMLClassName = false,
    ...rest
  } = props;
  const cns = clsx(
    "[ relative ]",
    {
      "[ overflow-hidden ]" : overflowHidden,
      "[ bg-white ]" : bg == 'white',
      "[ bg-gray-97 ]" : bg == 'gray-light' || bg == 'light-gray' || bg == 'gray',
      "[ bg-gradient-to-r from-indigo to-blue-60 ]" : bg == 'blue-gradient',
      "[ bg-gradient-to-r from-blue-10 to-purple ]" : bg == 'purple-gradient',
      "[ !py-0 ]" : pad == 'none',
      "[ md:py-16 ]" : pad == '',
      "[ py-6 md:py-8 ]" : pad == 'xs',
      "[ py-9 md:py-10 lg:py-12 ]" : pad == 'sm',
      "[ py-10 md:py-14 lg:py-20 ]" : pad == 'md',
      "[ py-20 md:py-24 lg:py-28 ]" : pad == 'lg',
      "[ py-24 md:py-28 lg:py-40 ]" : pad == 'xl',
      "[ !pt-0 ] ]" : topPad == 'none',
      "[ pt-6 md:pt-8 ]" : topPad == 'xs',
      "[ pt-9 md:pt-10 lg:pt-12 ]" : topPad == 'sm',
      "[ pt-12 md:pt-16 ]" : topPad == 'ms',
      "[ pt-10 md:pt-14 lg:pt-20 ]" : topPad == 'md',
      "[ pt-20 md:pt-24 lg:pt-28 ]" : topPad == 'lg',
      "[ pt-24 md:pt-28 lg:pt-40 ]" : topPad == 'xl',
      "[ !pb-0 ] ]" : botPad == 'none',
      "[ pb-6 md:pb-8 ]" : botPad == 'xs',
      "[ pb-9 md:pb-10 lg:pb-12 ]" : botPad == 'sm',
      "[ pb-12 md:pb-16 ]" : botPad == 'ms',
      "[ pb-10 md:pb-14 lg:pb-20 ]" : botPad == 'md',
      "[ pb-20 md:pb-24 lg:pb-28 ]" : botPad == 'lg',
      "[ pb-24 md:pb-28 lg:pb-40 ]" : botPad == 'xl',
      "[ text-white ]" : textColor == 'white'
    },
    { [styles[`frame-t-align-${textAlign}`]]: textAlign },
    className && { [className]: className },
  );
  return (
    <section {...rest} className={cns}>
      <div
        className={clsx(
          'container',
          {
            'wide-container': containerSize === 'wide',
            [styles.noContainerPadding]: noContainerPadding
          },
          containerClass,
        )}
      >
        {(title || subTitle) &&
          <div
            className={clsx(
              "[ lg:flex lg:justify-between ]",
              {
                "[ !mb-0 ]" : headlineMargin == 'none',
                "[ mb-6 md:mb-8 ]" : headlineMargin == 'sm',
                "[ mb-8 md:mb-12 ]" : headlineMargin == 'md',
                "[ mb-10 md:mb-16 ]" : headlineMargin == 'lg',
                "[ mb-14 md:mb-16 ]" : headlineMargin == 'press',
                "[ mb-10 md:mb-20 ]" : headlineMargin == 'xl',
              },
              styles[`headline-align-${textAlign}`],
            )}
          >
            <Headline
              title={title}
              subTitle={subTitle}
              headlineColumnOneSize={headlineColumnOneSize}
              headlineSecondColumn={headlineSecondColumn}
              watchVideoBtn={watchVideoBtn}
              tag={headlineTag}
              size={headlineSize}
              type={headlineType}
              className={titleClassName}
              isSubtitleHTML={isSubtitleHTML}
              subTitleClassName={subTitleClassName}
              applySubtitleHTMLClassName={applySubtitleHTMLClassName}
            />
          </div>
        }
        <>{children}</>
      </div>
    </section>
  );
};
