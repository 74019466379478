import Image, { ImageProps } from 'next/legacy/image';
import { FC } from 'react';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { isWEBPSupported } from 'ui/utils/isWebpSupported';
import { isString } from 'ui/utils/isString';
import { ResponsiveImageProps } from './ResponsiveImage.interface';

export const ResponsiveBreakpoint = 768; // because this is the value that we're using in css also to differentiate mobile and desktop
export const DesktopBreakpoint = 1024;
export const NavBarBreakpoint = 1040;

const changeMimeType = (src: string) => src.replace('.webp', '.jpg');

export const ResponsiveImage: FC<ResponsiveImageProps> = props => {
  const {
    alternativeText,
    src,
    layout,
    blurHash,
    width: imageWidth,
    height,
    formats,
    rootMargin,
    ...rest
  } = props;

  const [width] = useWindowsSize();

  let source: string = src!;

  if (formats) {
    if (width! < ResponsiveBreakpoint && formats.small) {
      source = formats.small.url;
    } else if (formats.medium) {
      source = formats.medium.url;
    } else if (formats.small) {
      source = formats.small.url;
    }
  }

  const imageProps: ImageProps = {
    layout,
    src: source,
    alt: alternativeText,
  };

  // if the layout is fill and then also if we provide width and height, the next/image give error
  if (layout !== 'fill') {
    imageProps.width = imageWidth;
    imageProps.height = height;
  }

  if (blurHash) {
    imageProps.placeholder = 'blur';
    imageProps.blurDataURL = blurHash;
  }

  if (
    !isWEBPSupported() &&
    isString(imageProps.src) &&
    // @ts-ignore
    imageProps.src.includes('.webp')
  ) {
    return (
      <Image
        alt=""
        lazyBoundary={rootMargin}
        // @ts-ignore
        {...{ ...imageProps, src: changeMimeType(imageProps.src) }}
        {...rest}
      />
    );
  }
  return <Image alt="" lazyBoundary={rootMargin} {...imageProps} {...rest} />;
};

ResponsiveImage.defaultProps = {
  layout: 'fixed',
  rootMargin: '400px',
  width: 40,
  height: 40,
};
