import { turingLogger } from 'ui/services/logger';
import { logClientExperimentsIfAny } from '../growthbook/client';
import { logServerExperimentIfAny } from '../growthbook/server';
import { QUERY_KEY, getFromSessionStorage } from '../helpers/session-helper';

const getPageviewPayload = (): string => {
  const queryString = getFromSessionStorage<string>(QUERY_KEY) || location.search;
  if (queryString) {
    const payload = {
      s: null,
      n: null,
      email_uuid: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      utm_term: null,
    };

    const params = new URLSearchParams(queryString);
    let isAnyParamExists = false;

    params.forEach((paramValue, paramKey) => {
      if (payload[paramKey] === null) {
        payload[paramKey] = paramValue;
        isAnyParamExists = true;
      }
    });

    if (isAnyParamExists) {
      return JSON.stringify(payload);
    }
  }

  return '';
};

/**
 * turingLoggerPageView
 * @returns {Promise<import('@turingenterprises/turing-client/dist/types').VisitorTokenPayload>}
 */
export const turingLoggerPageView = async () => {
  const payload = getPageviewPayload();
  await turingLogger.pageview(payload);
  logServerExperimentIfAny();
  logClientExperimentsIfAny();
  return turingLogger.getVisitorTokenPayload();
};
