const ArrowForward = ({fill = 'white'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask
        id="mask0_1102_43076"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1102_43076)">
        <path
          d="M16.6269 12.75H5.25C5.03718 12.75 4.85898 12.6782 4.7154 12.5347C4.5718 12.3911 4.5 12.2129 4.5 12.0001C4.5 11.7872 4.5718 11.609 4.7154 11.4655C4.85898 11.3219 5.03718 11.2501 5.25 11.2501H16.6269L11.4577 6.08083C11.309 5.93211 11.2356 5.75808 11.2375 5.55873C11.2394 5.35936 11.3179 5.18212 11.4731 5.027C11.6282 4.88214 11.8038 4.80714 12 4.802C12.1961 4.79687 12.3718 4.87187 12.5269 5.027L18.8672 11.3674C18.9608 11.461 19.0269 11.5597 19.0653 11.6635C19.1038 11.7674 19.123 11.8795 19.123 12.0001C19.123 12.1206 19.1038 12.2327 19.0653 12.3366C19.0269 12.4404 18.9608 12.5391 18.8672 12.6327L12.5269 18.9731C12.3884 19.1116 12.217 19.1824 12.0125 19.1856C11.808 19.1888 11.6282 19.118 11.4731 18.9731C11.3179 18.818 11.2404 18.6398 11.2404 18.4385C11.2404 18.2372 11.3179 18.059 11.4731 17.9039L16.6269 12.75Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ArrowForward;
