import { useEffect, useState } from 'react';

type UseWindowsSizeResult = [
  width: number | undefined,
  height: number | undefined,
];

export const useWindowsSize = () => {
  const [size, setSize] = useState<UseWindowsSizeResult>([
    undefined,
    undefined,
  ]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return size;
};
