import { Button } from 'ui/components/Button';
import { ResponsiveImage } from 'ui/components/ResponsiveImage';

declare global {
  interface Location {
    reload(forcedReload?: boolean): void;
  }
}

export const ErrorComponent = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4rem 0 12rem 0',
        }}
      >
        <ResponsiveImage
          src={'/img/speed-of-response.svg'}
          alt="error"
          alternativeText="women with laptop showing career growth"
          layout="intrinsic"
          width={400}
          height={400}
        />
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <h3
            style={{
              fontSize: '2.8rem',
              marginTop: '4rem',
            }}
          >
            Oops! Something went wrong.
          </h3>
          <h6
            style={{
              marginBottom: '3.2rem',
            }}
          >
            Let&apos;s try reloading the page. Just click the button below. If the problem persists, rest assured that
            our team is on it, working to fix things as quickly as possible.
          </h6>
          <Button
            text="Reload Page"
            onClick={() => {
              window.location.reload(true);
            }}
          />
          <h6
            style={{
              marginTop: '3.2rem',
              marginBottom: '3.2rem',
            }}
          >
            Thank you for your understanding, and sorry for the inconvenience.
          </h6>
        </div>
        <Button
          text="Go to home"
          onClick={() => {
            window.location.replace('/');
          }}
          arrowIcon
          outline
        />
      </div>
    </>
  );
};
