import { asLink } from '@prismicio/client';
// import cn from 'classnames';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, ButtonAppearance } from 'ui/components/Button';
import { CustomLink } from 'ui/components/CustomLink';
import { DesktopBreakpoint } from 'ui/components/ResponsiveImage';
import { useScrollY } from 'ui/hooks/useScrollY';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { constants } from 'ui/utils/constants';
import { SVGLibrary } from 'ui/svgs/library';
import Search from 'ui/components/Search';
import { useFeature } from '@growthbook/growthbook-react';
import { HeaderService } from 'ui/services/header/header.service';
import GetStartedModal from '../GetStarted/GetStartedModal';
import { getSearchResults } from '../../../../apps/web/service/elasticsearch';

export const Header = () => {
  const scrollY = useScrollY();
  const isFixedHeader = scrollY > 0;
  const [_document, set_document] = React.useState(null);
  const [activeDropDown, setActiveDropDown] = useState('');
  const [width] = useWindowsSize();
  const [navOpen, setNavOpen] = useState(false);
  const [showCTAOptions, setShowCTAOptions] = useState(false);
  const { t, lang } = useTranslation('header');
  const headerData: any = t('.', {}, { returnObjects: true });
  const [openGetStarted, setOpenGetStarted] = useState(false);
  const router = useRouter();

  const { value: searchFeatureConfig } = useFeature('search-rollout-geo');
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (searchFeatureConfig) {
      HeaderService.getCountry().then(country => {
        setShowSearch((searchFeatureConfig?.allowedCountries || []).includes(country));
      });
    }
  }, [searchFeatureConfig]);

  const handleDropDownClick = useCallback(
    (id: string) => () => {
      setActiveDropDown(state => (state === id ? '' : id));
    },
    [],
  );
  useEffect(() => {
    set_document(document);
  }, []);

  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      // desktop dropdowns
      if (width > DesktopBreakpoint) {
        const ele = document.getElementById(activeDropDown);
        const mainEle = document.getElementById(`top_nav_wrapper_${activeDropDown}`);
        if (ele && !ele.contains(event.target) && !mainEle.contains(event.target)) {
          setActiveDropDown('');
        }
      }
      // mobile menu
      if (width <= DesktopBreakpoint) {
        const mobileMenu = document.querySelector('.site-header');
        const hamburgerButton = event.target.closest('.site-header__burger');
        
        if (mobileMenu && !mobileMenu.contains(event.target) && !hamburgerButton) {
          setNavOpen(false);
          setShowCTAOptions(false);
        }
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropDown, width]);

  const handleDropdownClose = useCallback(() => {
    setActiveDropDown('');
    setNavOpen(false);
  }, []);
  if (!headerData.items) {
    return;
  }
  const renderCTAs = () => {
    return (
      <div className="[ flex flex-col justify-end lg:items-center lg:flex-row gap-2 xl:gap-4 ][ px-4 pb-32 lg:p-0 ][ h-full lg:h-auto ]">
        {headerData.items
          .filter(item => item.alignment_type === 'CTA')
          .map((item, i) => {
            const { text } = item;
            const isMenu = item.type === 'Menu';
            const isCTA = item.type === 'CTA';
            return (
              <React.Fragment key={`cta-${i}`}>
                <div
                  className={clsx("[ rounded-lg ]", {
                    "[ bg-gray-20" : activeDropDown === text.toLowerCase().split(' ').join('_'),
                  })}
                  role="presentation"
                  id={`top_nav_wrapper_${text.toLowerCase().split(' ').join('_')}`}
                  // Note the code below is hard coded to open the Login options only
                  {...(isMenu
                    ? {
                      onClick:
                        width < DesktopBreakpoint + 1
                          ? () => {
                            setShowCTAOptions(true);
                          }
                          : handleDropDownClick(text.toLowerCase().replaceAll(' ', '_')),
                    }
                    : {})}
                >
                  <Button
                    className={clsx("[ flex items-center justify-center ][ font-semibold ][ p-2 lg:py-2 lg:px-4 ][ text-white ][ rounded-lg ][ lg:text-14 xl:text-16 ][ w-full lg:w-auto ]", {
                      "[ border border-solid border-white lg:border-0 ][ hover:border-white hover:text-white hover:bg-transparent lg:hover:bg-gray-20 transition ]": !isCTA,
                    })}
                    // outline
                    text={text}
                    {...(isCTA
                      ? {
                        onClick: () => {
                          if (item.cta_type === 'Get Started') {
                            if (width > DesktopBreakpoint) {
                              setOpenGetStarted(true);
                            } else {
                              // redirect
                              router.push('/get-started');
                              setNavOpen(false);
                            }
                          }
                        },
                      }
                      : {
                        ...(width < DesktopBreakpoint + 1
                          ? { outline: true }
                          : { appearance: ButtonAppearance.LinkWhiteV2, postIcon: <ArrowDown /> }),
                      })}
                    id={`top-nav-${text.toLowerCase().split(' ').join('-')}`}
                    {...(isMenu ? { onClick: e => e.preventDefault() } : {})}
                    {...(!(isMenu && isCTA) ? { href: asLink(item.link) } : {})}
                  />
                </div>
                {_document && isMenu && (
                  <RenderDropdown item={item} activeDropDown={activeDropDown} setActiveDropDown={handleDropdownClose} />
                )}
                {isMenu && (
                  <div
                    className={clsx("[ lg:hidden ][ bg-gray-10 ][ absolute top-0 right-0 ][ w-full h-full max-w-82.5 ][ overflow-y-scroll overflow-x-hidden scrollbar-0 ] ][ transition-all ]", {
                      '[ translate-x-0 visible ]': showCTAOptions,
                      '[ translate-x-full invisible ]': !showCTAOptions,
                    })}
                  >
                    <div className="[ px-4 py-5 ][ flex items-center justify-center ][ border-b border-solid border-gray-40 ][ relative ]">
                      <div onClick={() => setShowCTAOptions(false)} className="[ flex items-center ][ absolute top-1/2 left-2 -translate-y-1/2 ][ text-white font-medium ][ cursor-pointer ]">
                        <SVGLibrary className="[ -rotate-90 ]" name="ExpandLess" /> Back
                      </div>
                      <div className="[ text-24 text-white font-medium ]">Login</div>
                    </div>
                    {item.navigation_list.data.navigation_links.map((navLink, index) => {
                      return (
                        <div className="[ py-5 px-2.5 space-y-6 ]" key={index}>
                          {navLink.link_to_document.data.navigation_items.map(navItem => {
                            return (
                              <div
                                key={`nav-item-${navItem.label}`}
                                onClick={() => {
                                  handleDropdownClose();
                                }}
                              >
                                <Button
                                  className="[ border-gray-60 rounded-lg ][ text-white font-medium ][ w-full ][ p-3 ]"
                                  // outline
                                  text={navItem.label}
                                  {...(isCTA
                                    ? {
                                      onClick: e => alert('Get Started'),
                                    }
                                    : {
                                      ...(width < DesktopBreakpoint + 1
                                        ? { outline: true }
                                        : { appearance: ButtonAppearance.LinkWhiteV2, postIcon: <ArrowDown /> }),
                                    })}
                                  id={`top-nav-${navItem.label.toLowerCase().split(' ').join('-')}`}
                                  href={asLink(navItem.link)}
                                  target={navItem.link_target}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    );
  };
  return (
      <header data-elastic-exclude className={clsx('site-header [ fixed top-0 left-0 z-[1001] ][ bg-gray-0 h-[72px] w-full ][ flex justify-center items-center ][ py-3 px-5 lg:px-14 ]', {
        'drop-shadow' : isFixedHeader })}>
        <div className="[ max-w-8xl w-full ][ flex justify-between items-center ]">
          <div className="[ flex flex-auto items-center justify-between gap-6 ]">
            <CustomLink href="/" prefetch={false}>
              <a id="top-nav-cta-turing-logo">
                <img loading="eager" alt="logo" src={'/img/logo_256.webp'} width={124} height={45} srcSet="/img/logo_128.webp 1x, /img/logo_256.webp 2x" />
              </a>
            </CustomLink>

            {/* Navigation Left side */}
            <div
              className={clsx(
                '[ bg-gray-10 lg:bg-transparent ][ grid lg:flex lg:justify-between lg:grow ][ transition-all ][ absolute top-full right-0 z-[1002] lg:relative lg:top-auto lg:right-auto ][ w-full h-[calc(100vh-72px)] max-w-82.5 lg:h-auto lg:max-w-none scrollbar-0 overflow-y-scroll overflow-x-hidden lg:overflow-y-visible ][ lg:visible lg:translate-x-0 ]', 
                {
                  'visible translate-x-0': navOpen,
                  'invisible translate-x-full' : !navOpen
                }
              )}
            >
              <nav className="[ flex flex-col items-center lg:flex-row ]">
                <ul className="[ flex flex-col lg:flex-row lg:gap-1 ][ mb-6 lg:mb-0 ][ w-full ][ text-16 font-normal ]">
                  {headerData.items
                    .filter(item => item.alignment_type === 'Navigation')
                    .map((item, i) => {
                      const { text } = item;
                      const isMenu = item.type === 'Menu';
                      const id = text.toLowerCase().split(' ').join('_');
                      return (
                        <React.Fragment key={`menu-item-${i}`}>
                          <li
                            {...(isMenu ? { onClick: handleDropDownClick(id) } : { onClick: handleDropdownClose })}
                            className={clsx(
                              '[ px-4 py-5 lg:p-2 ][ border-b border-solid border-gray-40 lg:border-0 lg:rounded-md ][ flex items-center justify-between lg:justify-center ][ lg:hover:bg-gray-20 transition ][ font-medium ]',
                              {
                                'lg:bg-gray-20': activeDropDown === id,
                                'p-0': !isMenu,
                              }
                            )}
                            key={`menu-list-${i}`}
                            role="presentation"
                            id={`top_nav_wrapper_${id}`}
                          >
                            <Button
                              className="[ lg:text-14 xl:text-16 ]"
                              text={text}
                              appearance={ButtonAppearance.LinkWhiteV2}
                              id={`top-nav-${text.toLowerCase().split(' ').join('-')}`}
                              {...(isMenu ? { onClick: e => e.preventDefault() } : { href: asLink(item.link) })}
                            />
                            {isMenu &&
                              (width < DesktopBreakpoint + 1 ? (
                                activeDropDown !== id ? (
                                  <SVGLibrary name='ExpandMore' />
                                ) : (
                                  <SVGLibrary name='ExpandLess' />
                                )
                              ) : (
                                <ArrowDown />
                              ))}
                          </li>
                          {_document && isMenu && (
                            <RenderDropdown
                              item={item}
                              activeDropDown={activeDropDown}
                              setActiveDropDown={handleDropdownClose}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                </ul>
              </nav>
              {width < DesktopBreakpoint + 1 && renderCTAs()}
            </div>
          </div>
          {/* CTA's */}
          {width > DesktopBreakpoint && renderCTAs()}
          <div className="[ flex lg:hidden ]">
            {showSearch && (
              <Search
                onSubmit={q => router.push({ pathname: '/search', query: { q }})}
                getQuickResults={(query) => getSearchResults(query, { size: 3 })}
              />
            )}
            <div className="[ lg:hidden ]">
              {navOpen ? (
                <Image
                  className="site-header__burger"
                  src={'/img/Hamburger_close.svg'}
                  onClick={() => setNavOpen(state => !state)}
                  width={48}
                  height={48}
                  alt="Hamburger_close.svg"
                  loading="eager"
                />
              ) : (
                <Image
                  className="site-header__burger"
                  src={'/img/Hamburger_menu.svg'}
                  onClick={() => setNavOpen(state => !state)}
                  width={48}
                  height={48}
                  alt="Hamburger_menu.svg"
                />
              )}
            </div>
          </div>
        </div>
        <GetStartedModal open={openGetStarted} onClose={() => setOpenGetStarted(false)} />
      </header>
  );
};
const RenderDropdown = ({ item, setActiveDropDown, activeDropDown }) => {
  const getLeftValue = useCallback(id => {
    const rects = document.getElementById(id)?.getBoundingClientRect();
    return rects?.left;
  }, []);
  const [width] = useWindowsSize();
  const { text } = item;
  const dropdown = (
    <>
      {item.drop_down_type === 'Full Width' ? (
        <div
          id={text && text.toLowerCase().replaceAll(' ', '_')}
          style={{
            display: activeDropDown === (text && text.toLowerCase().replaceAll(' ', '_')) ? 'flex' : 'none',
            // left:
            //       width < DesktopBreakpoint + 1 && item.drop_down_type !== 'Full Width'
            //         ? 0
            //         : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
          }}
          className="[ bg-gray-20 lg:bg-black/85 lg:backdrop-blur-md ][ lg:fixed lg:top-[72px] lg:z-[1000] ][ w-full ][ lg:flex lg:justify-center ]"
        >
          <div
            className="[ lg:max-w-[1440px] ][ lg:grid lg:gap-8 lg:pl-14 ][ text-white ]"
            style={{
              gridTemplateColumns:
                width > DesktopBreakpoint
                  ? `${(item.navigation_list.data.navigation_links.length === 2 ? '1.5fr ' : '1fr ').repeat(
                    item.navigation_list.data.navigation_links.length,
                  )}  1.7fr`
                  : '1fr',
            }}
          >
            {item.navigation_list.data.navigation_links.map((navLink, index) => {
              return (
                <div className="[ pl-6 mt-8 lg:p-0 lg:my-8 ]" key={index}>
                  <div className="[ border-b border-solid border-gray-30 ][ font-bold text-white text-14 ][ pb-4 ]">{navLink.item_text}</div>
                  {navLink.link_to_document.data.navigation_items.map((navItem, i) => {
                    return (
                      <React.Fragment key={`dropdown-${navItem.label}-${i}`}>
                        <LinkComponent
                          withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                          navItem={navItem}
                          setActiveDropDown={setActiveDropDown}
                          isLarge={true}
                        >
                          <>
                            {navItem.icon.url && (
                              <div>
                                <Image
                                  className="[ w-6 h-6 ][ max-w-none ]"
                                  src={navItem.icon.url}
                                  width={24}
                                  height={24}
                                  alt={navItem.label}
                                />
                              </div>
                            )}
                            <div>
                              <div className="[ font-bold ][ lg:text-14 xl:text-16 ]">{navItem.label}</div>
                              {navItem.description && <p className="[ text-12 ]">{navItem.description}</p>}
                            </div>
                          </>
                        </LinkComponent>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
            {item.resource_post_link && (
              <div className="[ bg-gray-10 ][ p-8 ]">
                <div className="[ text-12 font-bold ][ mb-4 ]">{item.resource_title}</div>
                <Image className="[ w-40 h-[105px] object-cover object-center ]" width={160} height={105} src={item.resource_post_link.data.image.url} alt="" />
                <div className="[ font-bold ][ my-2 ]">{item.resource_post_link.data.title}</div>
                <p className="[ text-14 mb-2 ]">{item.resource_post_link.data.description}</p>
                <CustomLink href={item.resource_post_link.url}>
                  <a onClick={() => setActiveDropDown('')} className="[ block mb-4 ][ text-white text-14 underline font-bold ][ hover:text-blue-50 hover:underline transition ]">
                    {item.resource_read_more_text}
                  </a>
                </CustomLink>
                <CustomLink href={item.see_all_resources_link}>
                  <a onClick={() => setActiveDropDown('')} className="[ block ][ text-white underline font-bold ][ hover:text-blue-50 hover:underline transition ]">
                    {item.see_all_resources_text}
                  </a>
                </CustomLink>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Render the content width dropdown
        <div
          id={text.toLowerCase().replaceAll(' ', '_')}
          style={{
            display: activeDropDown === text.toLowerCase().replaceAll(' ', '_') ? 'block' : 'none',
            left:
              width < DesktopBreakpoint + 1 && item.drop_down_type !== 'Full Width'
                ? 0
                : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
          }}
          className="[ bg-gray-20 lg:bg-black/85 lg:backdrop-blur-md ][ lg:fixed lg:top-[72px] lg:z-[1000] ][ lg:flex lg:justify-center ][ pl-6 lg:pl-0 ][ lg:rounded-b-xl ]"
        >
          <div
            style={{
              left:
                width < DesktopBreakpoint + 1
                  ? 0
                  : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
            }}
            className="[ max-w-72 ][ pr-2 py-2 lg:p-2 ][ lg:text-14 xl:text-16 ]"
          >
            {item.navigation_list.data.navigation_links.map((navLink, i) => {
              return (
                <React.Fragment key={`dropdown-${i}`}>
                  {navLink.link_to_document.data.navigation_items.map((navItem, i) => {
                    return (
                      <React.Fragment key={`dropdown-${navItem.label}-${i}`}>
                        <LinkComponent
                          withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                          navItem={navItem}
                          setActiveDropDown={setActiveDropDown}
                        >
                          <div>
                            <div className="[ font-bold ][ lg:text-14 xl:text-16 ]">{navItem.label}</div>
                            {navItem.description && <p className="[ text-12 mb-0 ]">{navItem.description}</p>}
                          </div>
                        </LinkComponent>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
  return width > DesktopBreakpoint ? createPortal(dropdown, document.getElementById('dropdownPortalElement')) : dropdown;
};

export const LinkComponent = ({ withCustomLink, navItem, setActiveDropDown, children, isLarge = false }) => {
  return withCustomLink ? (
    <Button target={navItem.link_target} href={asLink(navItem.link)} appearance={ButtonAppearance.InvisibleLink}>
      <a
        className={clsx("[ flex gap-2 ][ rounded-lg ][ text-white ][ lg:hover:bg-black transition ]",{
            '[ py-2 px-1 lg:p-2 my-4 ]' : isLarge,
            '[ pr-2 py-2 lg:p-2 ]' : !isLarge
          }
        )}
        onClick={() => setActiveDropDown('')}
      >
        {children}
      </a>
    </Button>
  ) : (
    <a
      className={clsx("[ flex gap-2 ][ rounded-lg ][ text-white ][ lg:hover:bg-black transition ]",{
        '[ py-2 px-1 lg:p-2 my-4 ]' : isLarge,
        '[ p-2 ]' : !isLarge
        }
      )}
      href={navItem.link?.url}
      target={navItem.link_target}
      onClick={() => setActiveDropDown('')}
    >
      {children}
    </a>
  );
};

export const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_905_3305)">
        <path
          d="M8.70834 11.71L11.2983 14.3C11.6883 14.69 12.3183 14.69 12.7083 14.3L15.2983 11.71C15.9283 11.08 15.4783 10 14.5883 10H9.40834C8.51834 10 8.07834 11.08 8.70834 11.71Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_3305">
          <rect width="24" height="24" fill="white" transform="translate(-0.00170898)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Header;
