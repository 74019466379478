import Script from 'next/script';
import { turingLoggerPageView } from './TuringLogger';

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;
export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

export const pageview = url => {
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
  });
  turingLoggerPageView();
};

export const GTM_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${GTM_ID}');
`;

const GAScripts = () => {
  return (
    <Script strategy="afterInteractive" id="google-analytics">
      {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GA_ID}', {
      page_path: window.location.pathname,
    });
  `}
    </Script>
  );
};

export default GAScripts;
