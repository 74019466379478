import Cookies from 'js-cookie';
import { getFromCookie } from 'ui/utils/helpers/session-helper';
import { RESULT_COOKIE_NAME, TOKEN_COOKIE_NAME } from '../config';
import { logExperiment } from '../logging';

export const logServerExperimentIfAny = () => {
  const token = getFromCookie(TOKEN_COOKIE_NAME);
  const experimentCookieValue = getFromCookie(RESULT_COOKIE_NAME);

  // To prevent logging the same record again
  Cookies.remove(RESULT_COOKIE_NAME);

  if (token && experimentCookieValue) {
    logExperiment(JSON.parse(experimentCookieValue));
  }
};
