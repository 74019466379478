import { SVGLibrary } from 'ui/svgs/library';
import { asLink } from '@prismicio/client';
import { CustomLink } from 'ui/components/CustomLink';
import cn from 'classnames';
import styles from './GetStarted.module.scss';
import { Button, ButtonAppearance } from 'ui/components/Button';

const Getstarted = ({ loadedAsPage = false, data }) => {
  const { title, sub_title, items } = data.data || data;
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.loadedAsPage]: loadedAsPage,
      })}
    >
      <div className={styles.header}>
        <div className={styles.h1}>{title}</div>
        <div className={styles.h4}>{sub_title}</div>
      </div>
      <div className={styles.cardsWrapper}>
        {items.map(item => (
          <Button href={asLink(item.cta_link)} target={item.cta_target} appearance={ButtonAppearance.InvisibleLink}>
            <div key={item.title} className={styles.card}>
              <div className={styles.contentWrapper}>
                <div className={styles.icon}>
                  <SVGLibrary name={item.icon} />
                </div>
                <div className={styles.tagline}>{item.tag_line} </div>
                <div className={styles.h3}>{item.title}</div>
                <p className={styles.subheading}>{item.sub_title}</p>
              </div>

              <div className={styles.link}>
                {item.cta_text} <SVGLibrary name="ArrowForward" />
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Getstarted;
