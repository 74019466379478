import { VisitorTokenPayload } from '@turingenterprises/turing-client/dist/cjs/types';
import { createContext, useContext } from 'react';
import { ImpervaUserInfo } from 'ui/types/imperva-user-info';

export interface IAppContext {
  visitor: VisitorTokenPayload | null;
  impervaUserInfo: ImpervaUserInfo | null;
}

export const AppContextDefaultValue = { visitor: null, impervaUserInfo: null };

export const AppContext = createContext(AppContextDefaultValue);

export const useAppContext = () => {
  const value = useContext<IAppContext>(AppContext);
  return value;
};
