import { HeadlineTag, HeadlineProps } from 'ui/components/Headline';
import { VideoModalButtonProps } from 'ui/components/VideoModalButton';

export interface SectionProps {
  bg?: SectionBackground;
  textAlign?: string;
  title?: HeadlineProps['title'];
  titleClassName?: string;
  watchVideoBtn?: VideoModalButtonProps['watchVideoBtn'];
  headlineMargin?: string;
  subTitle?: string;
  headlineColumnOneSize?: number;
  textColor?: string;
  className?: string;
  pad?: string;
  topPad?: string;
  botPad?: string;
  headlineSecondColumn?: string | JSX.Element;
  containerSize?: string;
  containerClass?: string;
  headlineTag?: HeadlineTag;
  headlineSize?: string;
  headlineType?: string;
  isSubtitleHTML?: boolean;
  overflowHidden?: string;
  subTitleClassName?: string;
  noContainerPadding?: string;
  id?: string;
  applySubtitleHTMLClassName?: boolean;
}

export enum SectionBackground {
  White = 'white',
  Gray = 'gray',
  GrayLight = 'gray-light',
  LightGray = 'light-gray',
  BlueGradient = 'blue-gradient',
  PurpleGradient = 'purple-gradient',
}
