import { useEffect, useState } from 'react';
import { Locale, LocaleEnabledPaths, Locales } from 'ui/config/locales';

const formattedPathsWhereLocalesAreEnabled = LocaleEnabledPaths.map(ele =>
  ele.replace(/(\[.*\])/, ''),
); // returns something like this ['/jobs', '/jobs/', '/remote-developer-jobs', '/remote-developer-jobs/'] stripping out '[id]' from the string or any other word enclosed in []

const isValidPath = (url: string, ele: string) => {
  const base = url
    .split(ele)
    .filter(e => e)
    .toString();
  return base.split('/').length === 1;
};

const allowClientLocale = (paths: string[], url: string) => {
  if (typeof url !== 'string') return false;
  return paths.some((ele = '') => {
    if (ele.slice(-1) !== '/') {
      // if the last char of the string is '/' then it means that its a template path. So want to do the comparison with startsWith instead of the eq operation
      return url === ele;
    } else return url.startsWith(ele) && isValidPath(url, ele);
  });
};

export const useClientDefaultLocale = (url = '') => {
  const [locale, setLocale] = useState('en');
  useEffect(() => {
    const [language] = (navigator?.language || '').split('-'); // we want to strip the country code if the user has set their default language something like 'pt-br'

    if (allowClientLocale(formattedPathsWhereLocalesAreEnabled, url)) {
      setLocale(Locales.includes(language as Locale) ? language : 'en'); // if the browser language set is one of the supported languages by us, then use it, else return the default which is 'en'
    }
  }, [url]);

  return locale;
};
