const locales = require('./config/supportedLanguages');

/** @type {import('next-translate').I18nConfig} */
module.exports = {
  locales: locales,
  defaultLocale: 'en',
  localeDetection: false,
  defaultNS: 'common',
  pages: {
    '*': ['common', 'header', 'getstarted', 'footer', 'search', 'sub_header'],
    '/[uid]': ['review']
  },
};
