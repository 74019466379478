import cn from 'classnames';
import { FC } from 'react';
import { VideoModalButton } from 'ui/components/VideoModalButton';
import { HeadlineProps, HeadlineTag } from './Headline.interface';
import styles from './Headline.module.scss';

export const Headline: FC<HeadlineProps> = props => {
  const {
    title,
    subTitle,
    headlineColumnOneSize,
    headlineSecondColumn,
    watchVideoBtn,
    tag: Tag = HeadlineTag.h2,
    size,
    type,
    className,
    isSubtitleHTML,
    subTitleClassName,
    applySubtitleHTMLClassName,
  } = props;
  const style = headlineColumnOneSize
    ? { maxWidth: `${headlineColumnOneSize}px` }
    : {};

  const subtitleHTMLClassName = cn(
    applySubtitleHTMLClassName,
    styles.subtitleHTML,
  );

  if (!headlineSecondColumn) {
    if (title || subTitle) {
      return (
        <div className={cn(styles.headlineCol)} style={style}>
          {title && (
            <Tag
              className={cn(
                styles.title,
                styles[`headline-size-${size}`],
                styles[`headline-type-${type}`],
                className,
              )}
            >
              {typeof title === 'function' ? title() : title}

              {watchVideoBtn && (
                <VideoModalButton watchVideoBtn={watchVideoBtn} />
              )}
            </Tag>
          )}

          {subTitle && (
            <>
              {isSubtitleHTML ? (
                <div
                  className={subtitleHTMLClassName}
                  dangerouslySetInnerHTML={{ __html: subTitle }}
                />
              ) : (
                <div className={cn(styles.subTitle, subTitleClassName)}>{subTitle}</div>
              )}
            </>
          )}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return (
      <>
        <div
          className={styles.headlineCol}
          style={style}
        >
          {title && (
            <Tag
              className={cn(
                styles.title,
                styles[`headline-size-${size}`],
                styles[`headline-type-${type}`],
              )}
            >
              {typeof title === 'function' ? title() : title}
            </Tag>
          )}
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>
        {headlineSecondColumn && (
          <div className={styles.headlineCol}>{headlineSecondColumn}</div>
        )}
      </>
    );
  }
};
