const Paths = {
  liveJobsMaster: '/remote-developer-jobs',
  review: '/review',
  press: '/press',
  company: '/company',
  jobs: '/jobs',

  liveJobsDetail: '/remote-developer-jobs/j/[jobIdSlug]',
  promotedLinkedinLiveJobsDetail: '/remote-developer-jobs/j/li-promoted/[jobIdSlug]',
  jobSpecific: '/jobs/[id]',
  kbPages: '/kb/[id]',
  liveJobsFilter: '/remote-developer-jobs/[id]',
  boundaryless: '/boundaryless',
  interviewQuestions: '/interview-questions/[id]',
};

module.exports = ['en', 'es', 'pt'];

module.exports.Paths = Paths;

module.exports.pathsWhereLocalesAreEnabled = Object.values(Paths);
