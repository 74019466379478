import ReactModal from 'react-modal';
import useTranslation from 'next-translate/useTranslation';
import { SVGLibrary } from 'ui/svgs/library';
import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './GetStartedModal.module.scss';
import Getstarted from './Getstarted';

const GetStartedModal = ({ open, onClose }) => {
  // getstarted
  const { t, lang } = useTranslation('getstarted');
  const [_document, set_document] = useState(null);
  const getstartedData: any = t('.', {}, { returnObjects: true });
  useEffect(() => {
    set_document(document);
  }, []);
  return (
    _document &&
    createPortal(
      <div
        className={cn(styles.modal, {
          [styles.open]: open,
          [styles.fadein]: open,
          [styles.fadeout]: !open,
        })}
      >
        {/* <ReactModal
        isOpen={open}
        contentLabel="onRequestClose Example"
        onRequestClose={onClose}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content}>
          <div className={styles.closeBtn} onClick={onClose}>
            <SVGLibrary name={'CloseModal'} />
          </div>
        </div>
        <Getstarted data={{data:getstartedData}} />
      </ReactModal> */}
        <div className={styles.content}>
          <div className={styles.closeBtn} onClick={onClose}>
            <SVGLibrary name={'CloseModal'} />
          </div>
        </div>
        <div className={styles.getStarted}>
          <Getstarted data={{ data: getstartedData }} />
        </div>
      </div>,
      document.getElementById('dropdownPortalElement'),
    )
  );
};

export default GetStartedModal;
