/* eslint-disable no-console */
import Cookies from 'js-cookie';
import { constants } from 'ui/utils/constants';
import { parseQuery, stringifyQuery } from '../query-helper';

export const QUERY_KEY = 'query-params-turing';
const REFERRER_QUERY_KEY = 'referrer';
export const REFERRER_SESSION_KEY = 'referrer-turing';

export const isBrowser = () => typeof window !== 'undefined';

export const isCookieEnabled = () => isBrowser() && navigator.cookieEnabled;

export const getFromLocalStorage = <T = any>(key: string): T | string | null => {
  if (isBrowser() && localStorage) {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
  }
  return null;
};

export const setToLocalStorage = <T>(key: string, data: T): void => {
  if (isBrowser() && localStorage) {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getFromSessionStorage = <T>(key: string): T | null => {
  if (isBrowser() && sessionStorage) {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  }
  return null;
};

export const setToSessionStorage = <T>(key: string, data: T): void => {
  if (isBrowser() && sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};

export const getFromCookie = (key: string): string | undefined => {
  if (isBrowser()) {
    return Cookies.get(key);
  }
  return undefined;
};

export const setToCookie = (key: string, data: string) => {
  if (isBrowser()) {
    Cookies.set(key, data);
  }
};

const storeQueryParams = (url?: string): void => {
  const [, search] = url?.split('?') || [];

  if (search && isBrowser()) {
    setToSessionStorage(QUERY_KEY, search);
    // Triggering the event to tell the URL builder that it's saved to session so re-build the url
    const startEvent = new Event(`saved-${QUERY_KEY}`);
    // To trigger the Event
    document.dispatchEvent(startEvent);
  }
};

const storeReferral = () => {
  if (!isBrowser()) {
    return;
  }
  const { referrer } = window.document;
  const { origin } = window.location;

  /*
    two conditions to store referrer in session storage
    - current referrer has value
    - current referrer is not our website
  */
  if (referrer && !referrer.startsWith(origin)) {
    // setToCookie(REFERRER_SESSION_KEY, referrer);
    // Storing it in session too
    sessionStorage.setItem(REFERRER_SESSION_KEY, referrer);
  } else {
    sessionStorage.setItem(REFERRER_SESSION_KEY, '');
  }
};

export const storageSession = (url?: string) => {
  storeQueryParams(isBrowser() ? window.location.search : url);
  storeReferral();
};

export const buildTrackUserUrl = (url = constants.DEVELOPER_FUNNEL_URL) => {
  if (!isBrowser()) {
    return url;
  }

  const query = getFromSessionStorage<string>(QUERY_KEY);
  const [urlWithoutQuery, urlWithQuery] = url.split('?');
  // const referrer = getFromCookie(REFERRER_SESSION_KEY);
  const referrer = sessionStorage.getItem(REFERRER_SESSION_KEY);

  const queryObj: any = {
    ...parseQuery(query || ''),
    ...parseQuery(urlWithQuery),
    ...(referrer && { [REFERRER_QUERY_KEY]: referrer }),
  };
  delete queryObj['uuid'];

  const queryParams = stringifyQuery(queryObj);

  return queryParams ? `${urlWithoutQuery}?${queryParams}` : urlWithoutQuery;
};
export const externalUrls = {
  'customers.turing.com': true,
  'staging-customers.turing.com': true,
  'developers.turing.com': true,
  'staging-developers.turing.com': true,
  'self-serve.turing.com': true,
  'uat-self-serve.turing.com': true,
};
// this function is used where me might get an internal url.
// In that case  we don't want to add any attributions thus use this function instead of directly using buildTrackUserUrl
export const getCtaURL = (url = constants.DEVELOPER_FUNNEL_URL) => {
  // add an external domain here if you want to generate the attribution for it
  let urlObject: URL;
  try {
    urlObject = new URL(url);
  } catch {
    return url;
  }

  if (externalUrls[urlObject.hostname]) {
    return buildTrackUserUrl(url);
  }

  return url;
};
