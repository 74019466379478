export enum ICON_NAME {
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  SHARE = 'SHARE',
  INFO = 'INFO',
  GLOBE = 'GLOBE',
  PERSON_SEARCH = 'PERSON_SEARCH',
  PERSON_SEARCH_OUTLINED = 'PERSON_SEARCH_OUTLINED',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  SWITCH_ACCOUNT_OUTLINED = 'SWITCH_ACCOUNT_OUTLINED',
  TASK = 'TASK',
  TASK_OUTLINED = 'TASK_OUTLINED',
  FACT_CHECK = 'FACT_CHECK',
  FACT_CHECK_OUTLINED = 'FACT_CHECK_OUTLINED',
  STARS = 'STARS',
  BULLET = 'BULLET',
}

export const ICONS_MAP = {
  [ICON_NAME.TWITTER]: {
    paths: [
      {
        d: 'M13.093 9.3155L21.283 0H19.3422L12.2308 8.08852L6.55101 0H0L8.58902 12.2313L0 22H1.94088L9.45067 13.4583L15.449 22H22L13.0925 9.3155H13.093ZM10.4347 12.339L9.56445 11.1211L2.6402 1.42965H5.62127L11.2092 9.25094L12.0795 10.4689L19.3431 20.6354H16.3621L10.4347 12.3395V12.339Z',
        fill: '#CCC',
      },
    ],
  },
  [ICON_NAME.INSTAGRAM]: {
    paths: [
      // {
      //   d: 'M12 0H5.2A6 6 0 0 0 3 .6a4.8 4.8 0 0 0-1.4 1 5.2 5.2 0 0 0-1.4 3A21.7 21.7 0 0 0 0 8v10.7A6 6 0 0 0 .6 21a5 5 0 0 0 3.3 2.6 8.5 8.5 0 0 0 1.7.3h12.7a8.2 8.2 0 0 0 1.8-.3 5 5 0 0 0 3.3-2.6 6 6 0 0 0 .6-2.3V5.2a5.9 5.9 0 0 0-.6-2.3 4.8 4.8 0 0 0-1-1.4 5.2 5.2 0 0 0-3-1.4A21.6 21.6 0 0 0 16 0Z',
      //   fill: 'none',
      // },
      {
        d: 'M12 3.1H8.3a6.5 6.5 0 0 0-2.1.5 4.5 4.5 0 0 0-2.6 2.6 6.5 6.5 0 0 0-.4 2.1v7.4a6.5 6.5 0 0 0 .4 2.1 4.5 4.5 0 0 0 2.6 2.6 6.5 6.5 0 0 0 2.1.4h7.4a6.5 6.5 0 0 0 2.1-.4 4.5 4.5 0 0 0 2.6-2.6 6.6 6.6 0 0 0 .4-2.1V8.3a6.6 6.6 0 0 0-.4-2.1 4.5 4.5 0 0 0-2.6-2.6 6.5 6.5 0 0 0-2.1-.4H12Zm-.8 1.6h4.4a5 5 0 0 1 1.6.4A3 3 0 0 1 19 6.8a4.9 4.9 0 0 1 .3 1.6 62 62 0 0 1 0 7.2 4.9 4.9 0 0 1-.3 1.6 3 3 0 0 1-1.7 1.7 4.9 4.9 0 0 1-1.6.3 62 62 0 0 1-7.2 0 5 5 0 0 1-1.6-.3A3 3 0 0 1 5 17.2a4.9 4.9 0 0 1-.3-1.6V8.4A5 5 0 0 1 5 6.8 3 3 0 0 1 6.8 5a4.9 4.9 0 0 1 1.6-.3h2.8Zm5.5 1.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM12 7.5a4.6 4.6 0 1 0 4.5 4.5A4.6 4.6 0 0 0 12 7.5ZM12 9a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z',
        fill: '#CCC',
      },
    ],
  },
  [ICON_NAME.FACEBOOK]: {
    paths: [
      {
        d: 'M26.25 14.9981C26.2496 12.8483 25.6333 10.7435 24.4739 8.93306C23.3145 7.12261 21.6606 5.68227 19.7081 4.78255C17.7555 3.88284 15.5861 3.56143 13.4566 3.85639C11.327 4.15134 9.32658 5.0503 7.69208 6.44683C6.05757 7.84337 4.85745 9.67901 4.23378 11.7364C3.61011 13.7938 3.58902 15.9869 4.173 18.0559C4.75698 20.1249 5.92158 21.9833 7.52892 23.411C9.13626 24.8388 11.119 25.776 13.2425 26.1119V18.2481H10.3863V14.9981H13.2425V12.5194C13.1814 11.9406 13.2484 11.3555 13.4386 10.8055C13.6287 10.2555 13.9375 9.75407 14.343 9.33664C14.7485 8.91922 15.2408 8.59605 15.7851 8.39001C16.3293 8.18398 16.9122 8.10012 17.4925 8.14438C18.3363 8.15618 19.1782 8.22971 20.0112 8.36438V11.1306H18.5913C18.3495 11.0983 18.1036 11.1208 17.8718 11.1965C17.6399 11.2722 17.4281 11.399 17.252 11.5677C17.0759 11.7364 16.94 11.9426 16.8544 12.1709C16.7688 12.3993 16.7357 12.644 16.7575 12.8869V14.9981H19.8825L19.3825 18.2481H16.7575V26.1094C19.4036 25.6909 21.8135 24.3418 23.5536 22.305C25.2937 20.2681 26.2499 17.6771 26.25 14.9981Z',
        fill: '#CCC',
      },
    ],
  },
  [ICON_NAME.LINKEDIN]: {
    paths: [
      {
        d: 'M24.585 26.2502H5.41247C4.97881 26.2565 4.56033 26.0906 4.24889 25.7888C3.93744 25.4869 3.75846 25.0738 3.75122 24.6402L3.75122 5.3602C3.75879 4.92665 3.93787 4.51377 4.24924 4.21201C4.56062 3.91024 4.9789 3.74418 5.41247 3.7502H24.585C25.0192 3.7435 25.4384 3.90919 25.7507 4.21096C26.063 4.51273 26.243 4.92598 26.2512 5.3602V24.6402C26.2433 25.0745 26.0635 25.488 25.7511 25.7898C25.4387 26.0916 25.0193 26.2572 24.585 26.2502ZM17.7025 14.8527C18.8275 14.8527 19.425 15.6477 19.425 17.1514V22.5852H22.825V16.7602C22.825 13.8977 21.3612 12.1889 18.91 12.1889C18.2949 12.1615 17.684 12.3034 17.144 12.5992C16.604 12.895 16.1555 13.3333 15.8475 13.8664V12.4252H12.45C12.485 13.2039 12.4625 19.6464 12.45 22.3802C12.45 22.5052 12.45 22.5777 12.45 22.5864H15.8475V16.9127C15.8326 16.6331 15.8707 16.3531 15.96 16.0877C16.092 15.7301 16.3291 15.4209 16.6401 15.2005C16.951 14.9801 17.3214 14.8588 17.7025 14.8527ZM7.17247 12.4252V22.5852H10.57V12.4252H7.17247ZM8.89497 7.5277C8.65248 7.50417 8.40776 7.53121 8.17625 7.6071C7.94475 7.68299 7.73151 7.80609 7.55002 7.9686C7.36852 8.13112 7.22271 8.32952 7.12181 8.55127C7.02091 8.77301 6.96711 9.01328 6.96382 9.25688C6.96052 9.50048 7.0078 9.74212 7.10266 9.96652C7.19752 10.1909 7.33791 10.3932 7.51494 10.5606C7.69197 10.7279 7.9018 10.8567 8.13117 10.9389C8.36053 11.021 8.60444 11.0546 8.84747 11.0377H8.86872C9.11144 11.0599 9.35613 11.0316 9.58732 10.9544C9.81851 10.8772 10.0312 10.7528 10.2118 10.5892C10.3925 10.4256 10.5372 10.2263 10.6368 10.0038C10.7365 9.7814 10.7889 9.5407 10.7907 9.29697C10.7925 9.05324 10.7437 8.81179 10.6474 8.58788C10.5511 8.36398 10.4094 8.1625 10.2312 7.9962C10.053 7.8299 9.84226 7.70239 9.61225 7.62175C9.38224 7.5411 9.13799 7.50908 8.89497 7.5277Z',
        fill: '#CCC',
      },
    ],
  },
  [ICON_NAME.YOUTUBE]: {
    paths: [
      {
        d: 'M14.9987 24.6365C14.9137 24.6365 6.42125 24.6302 4.31375 24.0602C3.73267 23.9018 3.20335 23.5937 2.77868 23.1666C2.35401 22.7395 2.04887 22.2084 1.89375 21.6265C1.50226 19.4397 1.31188 17.2217 1.325 15.0002C1.31181 12.7792 1.50218 10.5616 1.89375 8.37523C2.0486 7.79287 2.35362 7.26135 2.77829 6.83382C3.20297 6.4063 3.73243 6.09773 4.31375 5.93898C6.42125 5.37148 14.9137 5.36523 14.9987 5.36523C15.0837 5.36523 23.5787 5.37148 25.6862 5.93898C26.2676 6.09708 26.7971 6.40546 27.2215 6.83313C27.6459 7.26079 27.9501 7.79266 28.1037 8.37523C28.4979 10.5613 28.6895 12.779 28.6762 15.0002C28.6894 17.2215 28.4977 19.4392 28.1037 21.6252C27.9501 22.2075 27.6458 22.7391 27.2214 23.1663C26.797 23.5936 26.2675 23.9015 25.6862 24.059C23.575 24.6302 15.0837 24.6365 14.9987 24.6365ZM12.2 10.9327V19.0677L19.35 15.0002L12.2 10.9327Z',
        fill: '#ccc',
      },
    ],
  },
  [ICON_NAME.INFO]: {
    paths: [
      {
        d: 'M7.00001 0.833252C3.32001 0.833252 0.333344 3.81992 0.333344 7.49992C0.333344 11.1799 3.32001 14.1666 7.00001 14.1666C10.68 14.1666 13.6667 11.1799 13.6667 7.49992C13.6667 3.81992 10.68 0.833252 7.00001 0.833252ZM7.00001 10.8333C6.63334 10.8333 6.33334 10.5333 6.33334 10.1666V7.49992C6.33334 7.13325 6.63334 6.83325 7.00001 6.83325C7.36668 6.83325 7.66668 7.13325 7.66668 7.49992V10.1666C7.66668 10.5333 7.36668 10.8333 7.00001 10.8333ZM7.66668 5.49992H6.33334V4.16659H7.66668V5.49992Z',
        fill: '#444444',
      },
    ],
  },
  [ICON_NAME.GLOBE]: {
    paths: [
      {
        d: 'M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16V16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8V8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56V19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z',
        fill: '#2E6EDF',
      },
    ],
  },

  [ICON_NAME.PERSON_SEARCH]: {
    paths: [
      {
        d: 'M20 7H15V4C15 2.9 14.1 2 13 2H11C9.9 2 9 2.9 9 4V7H4C2.9 7 2 7.9 2 9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V9C22 7.9 21.1 7 20 7ZM9 12C9.83 12 10.5 12.67 10.5 13.5C10.5 14.33 9.83 15 9 15C8.17 15 7.5 14.33 7.5 13.5C7.5 12.67 8.17 12 9 12ZM12 18H6V17.57C6 16.97 6.36 16.42 6.92 16.18C7.56 15.9 8.26 15.75 9 15.75C9.74 15.75 10.44 15.9 11.08 16.18C11.63 16.42 12 16.96 12 17.57V18ZM13 9H11V4H13V9ZM17.25 16.5H14.75C14.34 16.5 14 16.16 14 15.75C14 15.34 14.34 15 14.75 15H17.25C17.66 15 18 15.34 18 15.75C18 16.16 17.66 16.5 17.25 16.5ZM17.25 13.5H14.75C14.34 13.5 14 13.16 14 12.75C14 12.34 14.34 12 14.75 12H17.25C17.66 12 18 12.34 18 12.75C18 13.16 17.66 13.5 17.25 13.5Z',
        fill: '#2E6EDF',
      },
    ],
  },
  [ICON_NAME.SWITCH_ACCOUNT]: {
    paths: [
      {
        d: 'M17 20H4V7C4 6.45 3.55 6 3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM14 5C15.66 5 17 6.34 17 8C17 9.66 15.66 11 14 11C12.34 11 11 9.66 11 8C11 6.34 12.34 5 14 5ZM7.76 16C9.23 14.17 11.47 13 14 13C16.53 13 18.77 14.17 20.24 16H7.76Z',
        fill: '#2E6EDF',
      },
    ],
  },
  [ICON_NAME.TASK]: {
    paths: [
      {
        d: 'M19.41 7.41L14.58 2.58C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.41ZM10.23 17.29L8.11 15.17C7.72 14.78 7.72 14.15 8.11 13.76C8.5 13.37 9.13 13.37 9.52 13.76L10.93 15.17L14.47 11.63C14.86 11.24 15.49 11.24 15.88 11.63C16.27 12.02 16.27 12.65 15.88 13.04L11.64 17.28C11.26 17.68 10.62 17.68 10.23 17.29ZM14 9C13.45 9 13 8.55 13 8V3.5L18.5 9H14Z',
        fill: '#2E6EDF',
      },
    ],
  },
  [ICON_NAME.FACT_CHECK]: {
    paths: [
      {
        d: 'M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM9 17H6C5.45 17 5 16.55 5 16C5 15.45 5.45 15 6 15H9C9.55 15 10 15.45 10 16C10 16.55 9.55 17 9 17ZM9 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H9C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13ZM9 9H6C5.45 9 5 8.55 5 8C5 7.45 5.45 7 6 7H9C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9ZM18.7 11.12L15.53 14.29C15.14 14.68 14.5 14.68 14.11 14.29L12.7 12.87C12.31 12.48 12.31 11.85 12.7 11.46C13.09 11.07 13.72 11.07 14.11 11.46L14.82 12.17L17.29 9.7C17.68 9.31 18.31 9.31 18.7 9.7L18.71 9.71C19.09 10.1 19.09 10.74 18.7 11.12Z',
        fill: '#2E6EDF',
      },
    ],
  },
  [ICON_NAME.STARS]: {
    paths: [
      {
        d: 'M7.99337 1.33325C4.31337 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.31337 14.6666 7.99337 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 7.99337 1.33325ZM10.1467 11.5933L8.00004 10.2999L5.85337 11.5933C5.60004 11.7466 5.28671 11.5199 5.35337 11.2333L5.92004 8.79325L4.03337 7.15992C3.81337 6.96659 3.93337 6.59992 4.22671 6.57325L6.72004 6.35992L7.69337 4.05992C7.80671 3.78659 8.19337 3.78659 8.30671 4.05992L9.28004 6.35325L11.7734 6.56659C12.0667 6.59325 12.1867 6.95992 11.96 7.15325L10.0734 8.78659L10.64 11.2333C10.7067 11.5199 10.4 11.7466 10.1467 11.5933Z',
        fill: '#444444',
      },
    ],
  },
  [ICON_NAME.BULLET]: {
    paths: [
      {
        d: 'M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z',
        fill: '#212121',
      },
    ],
  },
  [ICON_NAME.SHARE]: {
    paths: [
      {
        d: 'M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z',
        fill: '#fff',
      },
    ],
  },
  [ICON_NAME.PERSON_SEARCH_OUTLINED]: {
    paths: [
      {
        d: 'M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V7C0 6.45 0.195833 5.97917 0.5875 5.5875C0.979167 5.19583 1.45 5 2 5H7V2C7 1.45 7.19583 0.979167 7.5875 0.5875C7.97917 0.195833 8.45 0 9 0H11C11.55 0 12.0208 0.195833 12.4125 0.5875C12.8042 0.979167 13 1.45 13 2V5H18C18.55 5 19.0208 5.19583 19.4125 5.5875C19.8042 5.97917 20 6.45 20 7V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H2ZM2 18H18V7H13C13 7.55 12.8042 8.02083 12.4125 8.4125C12.0208 8.80417 11.55 9 11 9H9C8.45 9 7.97917 8.80417 7.5875 8.4125C7.19583 8.02083 7 7.55 7 7H2V18ZM4 16H10V15.55C10 15.2667 9.92083 15.0042 9.7625 14.7625C9.60417 14.5208 9.38333 14.3333 9.1 14.2C8.76667 14.05 8.42917 13.9375 8.0875 13.8625C7.74583 13.7875 7.38333 13.75 7 13.75C6.61667 13.75 6.25417 13.7875 5.9125 13.8625C5.57083 13.9375 5.23333 14.05 4.9 14.2C4.61667 14.3333 4.39583 14.5208 4.2375 14.7625C4.07917 15.0042 4 15.2667 4 15.55V16ZM12 14.5H16V13H12V14.5ZM7 13C7.41667 13 7.77083 12.8542 8.0625 12.5625C8.35417 12.2708 8.5 11.9167 8.5 11.5C8.5 11.0833 8.35417 10.7292 8.0625 10.4375C7.77083 10.1458 7.41667 10 7 10C6.58333 10 6.22917 10.1458 5.9375 10.4375C5.64583 10.7292 5.5 11.0833 5.5 11.5C5.5 11.9167 5.64583 12.2708 5.9375 12.5625C6.22917 12.8542 6.58333 13 7 13ZM12 11.5H16V10H12V11.5ZM9 7H11V2H9V7Z',
        fill: '#2B6FDF',
      },
    ],
  },
  [ICON_NAME.SWITCH_ACCOUNT_OUTLINED]: {
    paths: [
      {
        d: 'M12 9C12.8333 9 13.5417 8.70833 14.125 8.125C14.7083 7.54167 15 6.83333 15 6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6C9 6.83333 9.29167 7.54167 9.875 8.125C10.4583 8.70833 11.1667 9 12 9ZM6 13.75C6.75 12.8667 7.65 12.1875 8.7 11.7125C9.75 11.2375 10.85 11 12 11C13.15 11 14.25 11.2375 15.3 11.7125C16.35 12.1875 17.25 12.8667 18 13.75V2H6V13.75ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H16V20H2ZM12 7C11.7167 7 11.4792 6.90417 11.2875 6.7125C11.0958 6.52083 11 6.28333 11 6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6C13 6.28333 12.9042 6.52083 12.7125 6.7125C12.5208 6.90417 12.2833 7 12 7ZM8.7 14H15.3C14.8167 13.6667 14.2917 13.4167 13.725 13.25C13.1583 13.0833 12.5833 13 12 13C11.4167 13 10.8417 13.0833 10.275 13.25C9.70833 13.4167 9.18333 13.6667 8.7 14Z',
        fill: '#2B6FDF',
      },
    ],
  },
  [ICON_NAME.TASK_OUTLINED]: {
    paths: [
      {
        d: 'M3 16V9H5V16H3ZM9 16V9H11V16H9ZM0 7V5L10 0L20 5V7H0ZM4.475 5H15.525L10 2.25L4.475 5ZM0 20V18H12.05C12.0833 18.35 12.125 18.6875 12.175 19.0125C12.225 19.3375 12.3 19.6667 12.4 20H0ZM15 12.25V9H17V11.25L15 12.25ZM18 23C16.85 22.7167 15.8958 22.0542 15.1375 21.0125C14.3792 19.9708 14 18.8167 14 17.55V15L18 13L22 15V17.55C22 18.8167 21.6208 19.9708 20.8625 21.0125C20.1042 22.0542 19.15 22.7167 18 23ZM17.275 20L20.75 16.55L19.7 15.5L17.275 17.875L16.3 16.9L15.25 17.975L17.275 20Z',
        fill: '#2B6FDF',
      },
    ],
  },
  [ICON_NAME.FACT_CHECK_OUTLINED]: {
    paths: [
      {
        d: 'M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM3 14H8V12H3V14ZM12.55 12L17.5 7.05L16.075 5.625L12.55 9.175L11.125 7.75L9.725 9.175L12.55 12ZM3 10H8V8H3V10ZM3 6H8V4H3V6Z',
        fill: '#2B6FDF',
      },
    ],
  },
};
