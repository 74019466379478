import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { Section, SectionProps } from 'ui/sections/section';
import styles from './Loader.module.scss';

interface LoaderProps {
  sectionProps?: SectionProps;
  styles?: any;
}
// TODO: Use a skeleton instead of a basic loader
export const Loader: FC<LoaderProps> = ({
  sectionProps,
  styles: stylesProp,
}) => {
  return (
    <Section botPad="xl" topPad="lg" {...sectionProps}>
      <div
        data-testid="loader"
        className={styles.root}
        style={stylesProp}
      ></div>
    </Section>
  );
};
export default Loader;

export const LiveJobCardListSkeleton = props => {
  return (
    <ContentLoader
      uniqueKey="live-job-card-list"
      height={1340}
      width={'100%'}
      {...props}
      data-testid="LiveJobCardListSkeleton"
    >
      <rect x="0" y="0" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="150" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="300" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="450" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="600" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="750" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="900" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="1050" rx="0" ry="0" width="360" height="148" />
      <rect x="0" y="1200" rx="0" ry="0" width="360" height="148" />

      <rect x="0" y="0" rx="0" ry="0" width="838" height="235" />
      <rect x="0" y="236" rx="0" ry="0" width="838" height="1169" />
    </ContentLoader>
  );
};

export const LiveJobDetailSkeleton = props => {
  return (
    <ContentLoader
      uniqueKey="live-job-detail"
      height={1340}
      width={'100%'}
      {...props}
      data-testid="LiveJobDetailSkeleton"
    >
      <rect x="0" y="0" rx="0" ry="0" width="838" height="235" />
      <rect x="0" y="236" rx="0" ry="0" width="838" height="1169" />
    </ContentLoader>
  );
};

export const LiveJobCardSkeleton = props => {
  return (
    <ContentLoader
      data-testid="LiveJobCardSkeleton"
      height={148}
      width={'100%'}
      uniqueKey="live-job"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width={'100%'} height="148" />
    </ContentLoader>
  );
};
