import { EnvironmentDomains } from 'ui/config/environment';

export const isProd = () => {
  return process.env.DEPLOYMENT_ENVIRONMENT === 'production';
};

export const isStaging = () => {
  return process.env.NEXT_PUBLIC_SUBDOMAIN === EnvironmentDomains.Staging;
};

export const isNonProdEnvironment = () => {
  return process.env.NEXT_PUBLIC_SUBDOMAIN !== EnvironmentDomains.Prod;
};

export const getDomain = () => {
  if (!isProd()) {
    return 'https://dev.turing.com';
  }
  if (isStaging()) {
    return 'https://staging.turing.com';
  }
  return 'https://www.turing.com';
};

export enum PublicationStates {
  LIVE = 'LIVE',
  PREVIEW = 'PREVIEW',
}

export const publicationState = (): PublicationStates => {
  if (isProd()) {
    return PublicationStates.LIVE;
  }
  return PublicationStates.PREVIEW;
};

export const staticGenerationLimit = () => {
  return process.env.DEPLOYMENT_ENVIRONMENT === 'production' ? 3000 : 3;
};
