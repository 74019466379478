import { turingLogger } from 'ui/services/logger';
import { LoggingData, LogPayload } from './logging.interface';

export const logExperiment = (payload: LoggingData) => {
  const { visitorId, sessionId } = turingLogger.getVisitorTokenPayload() || {};

  const { feature, experimentKey, variationId, value } = payload;
  const logData: LogPayload = {
    product: 'TURING_WEBSITE',
    feature: feature,
    experimentKey: experimentKey,
    experimentVariationId: variationId.toString(),
    cUserId: visitorId,
    uuid: sessionId,
    featureValue: null,
  };
  if (typeof value === 'object') {
    logData.featureValue = JSON.stringify(value);
  } else {
    logData.featureValue = String(value);
  }
  turingLogger.log('Experiment', logData, {
    logName: 'EXPERIMENTS',
  });
};
