import { useRouter } from 'next/router';
import Link from 'next/link';
import { FC } from 'react';
import styles from './PreviewBar.module.scss';

export const PreviewBar: FC = () => {
  const { isPreview } = useRouter();

  if (isPreview) {
    return (
      <div className={styles.previewBar} data-testid="previewBar">
        <span className={styles.previewBar__label}>
          Preview mode is enabled!
        </span>
        <Link href={{ pathname: '/api/preview', query: { disable: true } }} legacyBehavior>
          <a className={styles.previewBar__close} data-testid="previewBarClose">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              viewBox="0 0 48 48"
            >
              <path
                fill="currentColor"
                d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"
              />
            </svg>
          </a>
        </Link>
      </div>
    );
  }

  return null;
};
