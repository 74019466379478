exports.slugify = text => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // remove diacritics
    .replace(/[^a-z]+|\s/g, '-') // replace everything except letters and space with a single dash
    .replace(/-{2,}/g, '-') // replace consecutive dashes with a single one
    .replace(/-$/g, '') // remove single trailing dash
    .replace(/^-/g, ''); // remove single leading dash
};
