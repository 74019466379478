import {
  getFromLocalStorage,
  setToLocalStorage,
} from 'ui/utils/helpers/session-helper';

export function isWEBPSupported() {
  const supported = getFromLocalStorage('web_supported');
  if (supported !== null) return supported;

  try {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      const check =
        elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      setToLocalStorage('web_supported', check);
      return check;
    } else {
      // very old browser like IE 8, canvas not supported
      setToLocalStorage('web_supported', false);
      return false;
    }
  } catch {}
}
