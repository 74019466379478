import { FC } from 'react';
import { ICONS_MAP, ICON_NAME } from './Social/icon-const';

interface CustomIconProps {
  iconName: ICON_NAME;
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  onClick?: any;
  className?: any;
}

export const CustomIcon: FC<CustomIconProps> = ({
  iconName,
  width,
  height,
  viewBox,
  fill,
  onClick,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      onClick={onClick}
      className={className}
    >
      {ICONS_MAP[iconName]?.paths?.map(
        ({
          path,
          d,
          opacity,
          fill,
          stroke,
          strokeLinecap,
          strokeLinejoin,
          fillRule,
          clipRule,
          className,
          transform,
        }: any) => (
          <path
            key={d}
            d={d}
            path={path}
            opacity={opacity}
            className={className}
            transform={transform}
            fill={fill}
            stroke={stroke}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            fillRule={fillRule}
            clipRule={clipRule}
          />
        ),
      )}
    </svg>
  );
};
