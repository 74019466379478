import { asLink } from '@prismicio/client';
import { useRouter } from 'next/dist/client/router';
import Image from 'next/image';
import { FC } from 'react';
import { Button, ButtonAppearance, ButtonSize } from 'ui/components/Button';
import { CustomLink } from 'ui/components/CustomLink';
// import { LogoNew } from 'ui/svgs/LogoNew';
import { PrismicNextImage } from '@prismicio/next';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { SVGLibrary } from 'ui/svgs/library';
import { constants } from 'ui/utils/constants';
import { addQueryToURL } from 'ui/utils/helpers/url-helper';
import { socialLinks } from './Footer.constants';
import styles from './Footer.module.scss';

export const Footer = ({ navQueries }) => {
  const { t, lang } = useTranslation('footer');
  const footerData: any = t('.', {}, { returnObjects: true });
  const {
    logo,
    inline_turing_logo,
    navigation,
    aicpa_logo,
    inline_aicpa_logo,
    secondary_footer_links,
    social_media_logos,
    copy_rights,
  } = footerData;
  const { navigation_items: secondary_navigation_items } = secondary_footer_links?.data || {};

  const { locale } = useRouter();
  // const footerData = useMemo(() => {
  //   switch (locale) {
  //     case 'en':
  //       return FooterENData;
  //     case 'es':
  //       return FooterESData;
  //     case 'pt':
  //       return FooterPTData;
  //     default:
  //       return FooterENData;
  //   }
  // }, [locale]);

  const openPrivacySetting = () => {
    (window as any).Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  };

  const SocialIcons = () => {
    return (
      <ul className="[ flex items-center gap-1 ]" data-testid="socialLinks">
        {social_media_logos?.length > 0 &&
          social_media_logos.map((item, i) => {
            const { inline_svg, logo, link, link_target } = item;
            let socialIcon = null;
            if (inline_svg) {
              const socialLink = socialLinks.find(link => link.displayName === inline_svg);
              if (socialLink) {
                socialIcon = socialLink.icon;
              }
            }
            return (
              <li key={i}>
                <Button href={asLink(link)} className="[ after:hidden ]" target={link_target} rel="noreferrer" appearance={ButtonAppearance.LinkGray}>
                  <span className="[ block ][ p-0.5 ]">
                    {inline_svg ? socialIcon : <PrismicNextImage field={logo} />}
                  </span>
                </Button>
              </li>
            );
          })}
      </ul>
    );
  };
  return (
    <footer data-elastic-exclude className="wide-container [ w-full ][ bg-gray-0 text-white ]">
      <div className="[ py-16 px-5 md:py-20 md:px-16 ]">
        <div className="[ flex flex-col md:flex-row md:gap-14 md:flex-wrap lg:grid lg:grid-cols-5 ][ mb-12 md:mb-20 ]">
          <div className="[ w-full lg:w-auto ]">
            <CustomLink href="/">
              <a id="footer-logo">
                {inline_turing_logo ? <SVGLibrary name={inline_turing_logo} /> : <PrismicNextImage field={logo} />}
              </a>
            </CustomLink>
          </div>
          {navigation && <FooterNav navList={navigation} navQueries={navQueries} />}
        </div>
        <hr className="[ m-0 ][ border-b border-solid border-gray-60 ]" />

        <div className="[ flex flex-col gap-8 lg:flex-row lg:justify-between lg:items-center ][ py-8 ]">
          <div className="[ flex items-center order-1 lg:order-0 ][ md:ml-auto lg:ml-0 ]">
            {inline_aicpa_logo ? (
              <Image src={'/img/aicpa.webp'} loading="lazy" width={50} height={49.72} alt="aicpa" />
            ) : (
              <PrismicNextImage field={aicpa_logo} />
            )}
          </div>
          <div className="[ flex flex-col gap-4 md:flex-row lg:order-1 md:items-center md:justify-between lg:gap-12 ]">
            <div className="[ flex flex-col gap-4 md:flex-row md:items-center md:gap-6 lg:gap-12 ]">
              {secondary_navigation_items?.length > 0 &&
                secondary_navigation_items.map((item, i) => {
                  const id = `footer-link-${item.label.toLowerCase().split(' ').join('-')}`;
                  if (!item?.link?.url) {
                    return (
                      <div key={i}>
                        <a
                          key={i}
                          className="[ text-white text-14 ][ after:bg-white ]"
                          id={id}
                          onClick={item.cta_click === 'Privacy Setting' ? openPrivacySetting : null}
                        >
                          {item.label}
                        </a>
                      </div>
                    );
                  }

                  return (
                    <div key={i}>
                      <Button className="[ text-white text-14 ][ after:bg-white ]" key={i} href={asLink(item.link)} target={item.link_target} rel="noreferrer" id={id} appearance={ButtonAppearance.LinkGray}>
                        {item.label}
                      </Button>
                    </div>
                  );
                })}
            </div>
            <div className="[ mt-2 md:mt-0 ]">
              <SocialIcons />
            </div>
          </div>
        </div>
        <div className="[ flex flex-col-reverse gap-8 md:flex-row-reverse md:items-center ][ text-14 text-white ]">
          <span className="">{copy_rights}</span>
          <address className="">{'1900 Embarcadero Road Palo Alto, CA, 94303'}</address>
        </div>
      </div>
    </footer>
  );
};

interface FooterNavProps {
  navList: any[];
  navQueries: any;
}
const FooterNav: FC<FooterNavProps> = ({ navList, navQueries }) => {
  const navData = getColumnGroups(navList);
  return (
    <>
      {Object.keys(navData).map((column, i) => (
        <div className="[ text-white ]" key={i}>
          {navData[column].map((item, i) => {
            const { title, footer_navigation_links } = item;
            const { navigation_items } = footer_navigation_links?.data || {};

            return (
              <div key={i}>
                <h5 className={clsx("[ text-white text-16 font-medium ][ mb-6 ]", i > 0 ? "mt-10" : null)}>{title}</h5>
                {navigation_items && (
                  <ul className="[ space-y-4 ]">
                    {navigation_items.map((item: any, i: number) => {
                      const { label, link, link_target, icon } = item;
                      if (link?.url) {
                        return (
                          <li key={i}>
                            <LinkComponent
                              href={
                                navQueries && navQueries[link.url]
                                  ? addQueryToURL(link.url, navQueries[link.url])
                                  : link.url
                              }
                              link_target={link_target}
                              className="[ text-white text-14 ][ after:bg-white ]"
                            >
                              <div className="[ flex items-center ][ [&>svg]:max-h-6 ]">
                                <span>{label}</span>
                                {/* // TODO inline icon can be laced */}
                                {icon?.url ? (
                                  <SVGLibrary name={'LinkForwardArrow'} />
                                ) : (
                                  <PrismicNextImage field={icon} />
                                )}
                              </div>
                            </LinkComponent>
                          </li>
                        );
                      }
                      return (
                        <li key={i}>
                          <Button
                            new_tab={link_target}
                            href={
                              navQueries && navQueries[link.url]
                                ? addQueryToURL(link.url, navQueries[link.url])
                                : link.url
                            }
                            appearance={ButtonAppearance.LinkGraySmall}
                            text={label}
                            size={ButtonSize.Small}
                            id={`footer-link-${label?.toLowerCase().split(' ').join('-')}`}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

const LinkComponent = ({ href, link_target, className, children }) => {
  const withCustomLink = !href?.includes(constants.BLOG_URL_PART);
  if (withCustomLink) {
    return (
      <Button href={href} target={link_target} appearance={ButtonAppearance.LinkGray} className={className}>
        {children}
      </Button>
    );
  }
  return (
    <a href={href} target={link_target} className={className}>
      {children}
    </a>
  );
};

const getColumnGroups = (nav: any) => {
  const columnGroups = {};
  nav.forEach((item: any) => {
    const { column } = item;
    if (!columnGroups[column]) {
      columnGroups[column] = [];
    }
    columnGroups[column].push(item);
  });
  // sorting by row number
  Object.keys(columnGroups).forEach(column => {
    columnGroups[column].sort((a, b) => a.row - b.row);
  });
  return columnGroups;
};
