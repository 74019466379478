import { FC, useMemo, useState } from 'react';

import cn from 'classnames';

import dynamic from 'next/dynamic';
import YouTube from 'react-youtube';
import styles from './videoModalButton.module.scss';

const DynamicReactModal = dynamic(import("react-modal"));
const DynamicYoutube = dynamic(import("react-youtube"));
const videoOpts = {
  height: '390',
  width: '640',
  playerVars: {
    autoplay: 1,
    playsinline: 0,
    modestbranding: 1,
    start: 0,
    rel: 0,
    fs: 0,
  },
};

const modalStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'visible',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};
export interface VideoModalButtonProps {
  watchVideoBtn: {
    videoId: string;
    title: string;
  };
  className?: string;
  showIcon?: boolean;
}

export const VideoModalButton: FC<VideoModalButtonProps> = ({
  watchVideoBtn,
  className,
  showIcon = true,
}) => {
  const { videoId, title } = watchVideoBtn;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => openModal()}
        className={cn(styles.btnVideo, className)}
      >
        {showIcon && <i className={cn(styles['btnVideo-icon'], 'icon-play')} />}
        {title}
      </button>

      <DynamicReactModal
        isOpen={modalIsOpen}
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalIsOpen(false)}
        contentElement={(props, children) => <div {...props}>{children}</div>}
        ariaHideApp={false}
      >
        <span
          role="presentation"
          onClick={() => setModalIsOpen(false)}
          className={styles.modalClose}
        >
          close
        </span>
        <VideoPlayer videoId={videoId} />
      </DynamicReactModal>
    </>
  );
};

interface VideoPlayerProps {
  videoId: string;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ videoId }) => {
  return (
    <div className="ModalVideoWrap">
      <DynamicYoutube videoId={videoId} opts={videoOpts} />
    </div>
  );
};
