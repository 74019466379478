import { FC, HTMLProps, PropsWithChildren, useEffect, useState } from 'react';
import cn from 'classnames';
import { QUERY_KEY, getCtaURL, externalUrls } from 'ui/utils/helpers/session-helper';
import type { LinkField } from '@prismicio/client';
import { PrismicNextLink } from '@prismicio/next';
import { CustomLink } from 'ui/components/CustomLink/CustomLink';
import { ButtonColor, ButtonProps, ButtonSize } from './button.interface';

import styles from './button.module.scss';

export const Button: FC<PropsWithChildren<ButtonProps>> = props => {
  const {
    field,
    href,
    text,
    appearance,
    color,
    outline,
    size,
    className,
    new_tab = false,
    rounded = false,
    marginTop,
    iconType,
    arrowIcon,
    loading,
    cta,
    onClick,
    prefetch,
    stretch,
    invert,
    type,
    removeLinkAnimation,
    children,
    icon,
    onMouseEnter,
    locale,
    dataTestid,
    postIcon,
    target,
    baseBtn,
    ...rest
  } = props;
  const url = (field as any)?.url || cta || href;
  const [fetchedCtaUrl, setFetchedCtaUrl] = useState<string>(url);

  const [init, setInit] = useState(false);

  useEffect(() => {
    setFetchedCtaUrl(getCtaURL(url));

    try {
      const urlObj = new URL(url);
      if (externalUrls[urlObj.hostname] && !init) {
        document.addEventListener(`saved-${QUERY_KEY}`, () => {
          setInit(true);
        });
        () => {
          document.removeEventListener(`saved-${QUERY_KEY}`, () => null);
        };
      }
    } catch (error) { }
  }, [url, init, fetchedCtaUrl]);
  const renderIcon = () => {
    return (
      <span data-testid="renderIcon" className={styles['btn-icon']}>
        {icon}
      </span>
    );
  };

  const cl = cn(
    { [styles.btn]: !appearance },
    { [styles[`${baseBtn}`]]: !appearance },
    { [styles[`btn-${appearance}`]]: appearance },
    { [styles[`btn-outline`]]: outline },
    { [styles[`btn-rounded-sm`]]: !rounded },
    { [styles[`btn-rounded`]]: rounded },
    { [styles.loading]: loading },
    styles[`size-${size}`],
    { [styles['btn-arrow']]: iconType === 'arrow' || arrowIcon },
    { [styles['btn-video']]: iconType === 'video' },
    { [styles.stretch]: stretch },
    { [styles.invert]: invert },
    { [styles['btn-link-remove-animation']]: removeLinkAnimation },
    className,
  );

  const style = {
    '--btn-color': invert ? `var(--btn-bg-color-${color})` : `var(--btn-color-${color})`,
    '--btn-bg-color': invert ? `var(--btn-color-${color})` : `var(--btn-bg-color-${color})`,
    '--btn-color-hover': invert ? `var(--btn-bg-color-${color}-hover)` : `var(--btn-color-${color})-hover`,
    '--btn-bg-color-hover': invert ? `var(--btn-color-${color}-hover)` : `var(--btn-bg-color-${color}-hover)`,
    marginTop: marginTop,
  };

  const buttonType = type || 'button';

  if (onClick) {
    return (
      <button
        onClick={onClick}
        type={buttonType as any}
        className={cl}
        style={style}
        {...(rest as HTMLProps<HTMLButtonElement>)}
        onMouseEnter={onMouseEnter}
        data-testid={dataTestid ? dataTestid : 'button'}
      >
        {icon && renderIcon()}
        {text || children}
        {postIcon && postIcon}
      </button>
    );
  }

  if (field) {
    return (
      <PrismicNextLink
        className={cl}
        style={style}
        onMouseEnter={onMouseEnter}
        field={{ ...field, url: fetchedCtaUrl } as LinkField}
        target={target || '_self'}
        data-testid={dataTestid ? dataTestid : 'prismicLinkButton'}
      >
        {icon && renderIcon()}
        {text || children}
        {postIcon && postIcon}
      </PrismicNextLink>
    );
  }

  return (
    <CustomLink href={fetchedCtaUrl} prefetch={prefetch} locale={locale} {...rest}>
      <a
        className={cl}
        style={style}
        {...(target && { target })}
        {...(new_tab && { target: '_blank', rel: 'noreferrer' })}
        {...(rest as HTMLProps<HTMLAnchorElement>)}
        data-testid={dataTestid ? dataTestid : 'customLinkButton'}
        onMouseEnter={onMouseEnter}
      >
        {icon && renderIcon()}
        {text || children}
        {postIcon && postIcon}
      </a>
    </CustomLink>
  );
};

Button.defaultProps = {
  color: ButtonColor.Primary,
  size: ButtonSize.Medium,
};
