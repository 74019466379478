export const decodeHtmlEntities = (str) => {
    const entities = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#39;': "'",
      '&apos;': "'",
      '&nbsp;': ' ',
    };
    
    return str.replace(/&[a-zA-Z0-9#]+;/g, (match) => entities[match] || match);
  };
  