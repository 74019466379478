import Head from 'next/head';
import { useRouter } from 'next/router';
import { Locales } from 'ui/config/locales';

/**
 *
 * @param {{ variants: { [key: string]: { slug: string } }, paramKey: string }} pageAttributes
 * @param {import ('next/router').NextRouter} router
 * @returns {import('react').ReactNode}
 */
export const generateHrefLang = (
  attributes,
  { asPath, pathname, locale, query }: any,
) => {
  let hrefLangTags = null;
  if (attributes) {
    const currentPageSlug = query[attributes.paramKey];
    if (!attributes.variants[locale] && currentPageSlug)
      // if self referencing value is not present then add it
      attributes.variants[locale] = {
        locale,
        slug: currentPageSlug,
      };
    hrefLangTags = generateHrefLangTagsFromAttributes(attributes, pathname);
  } else {
    hrefLangTags = generateHrefLangTagsFromPath(asPath);
  }
  return hrefLangTags;
};

export const HrefLangTags = ({ attributes = null }) => {
  const router = useRouter();

  return <Head>{generateHrefLang(attributes, router)}</Head>;
};

const generateHrefLangTagsFromAttributes = (attributes, pathname) => {
  const nodes = [];
  ['x-default', ...Locales].forEach(iteratedLocale => {
    const variant =
      attributes.variants?.[
        iteratedLocale === 'x-default' ? 'en' : iteratedLocale
      ];
    if (variant && variant.slug) {
      const url = pathname.replace(`[${attributes.paramKey}]`, variant.slug);
      nodes.push(getHrefTag(iteratedLocale, url));
    }
  });

  return nodes;
};
const generateHrefLangTagsFromPath = asPath => {
  const [pathWithoutQueryParmas] = asPath.split(/[?#]/);

  const hrefLangTags = ['x-default', ...Locales].map(locale =>
    getHrefTag(locale, pathWithoutQueryParmas),
  );

  return hrefLangTags;
};

const getHrefTag = (locale, url) => {
  const isDefaultLanguage = locale === 'en' || locale === 'x-default';
  const path = isDefaultLanguage ? url : `/${locale + url}`;
  return (
    <link
      key={`href-lang-tag-${locale}`}
      rel="alternate"
      href={`https://www.turing.com${path}`}
      hrefLang={locale}
    />
  );
};
