import { Locale } from 'ui/config/locales';
import { slugify } from 'ui/utils/slugify';
import { findContentByAbbreviation } from 'ui/utils/helpers/jobIdSlug.helper';

const normalizePublicTitlle = (publicTitle: string, locale: Locale, jobData: any) => {
  const jobLanguageContent = findContentByAbbreviation(jobData?.jobLanguageContent, locale);
  const translatedPublicTitle = jobLanguageContent?.publicTitle;
  return translatedPublicTitle || publicTitle;
};

export const onlyAlphabetsRegex = /[a-zA-Z]+/g;

export const getLiveJobDetailUrlForMobileClick = (
  jobId: string,
  publicTitle: string,
  locale: Locale = Locale.English,
  jobData: any,
) => {
  const normalizedPublicTitle =
    locale !== Locale.English ? normalizePublicTitlle(publicTitle, locale, jobData) : publicTitle;
  const urlEncodedRole = slugify(normalizedPublicTitle);
  const normalizedLocale = getLocaleWithoutTheDefault(locale);
  return `${normalizedLocale}/remote-developer-jobs/j/${urlEncodedRole}-${jobId}`;
};

export const getLiveJobDetailPageUrl = ({
  jobId,
  publicTitle = '',
  locale = Locale.English,
}: {
  jobId: string;
  publicTitle?: string;
  locale?: Locale;
}) => {
  const urlEncodedRole = slugify(publicTitle);
  const normalizedLocale = getLocaleWithoutTheDefault(locale);
  return `${normalizedLocale}/remote-developer-jobs/j/${urlEncodedRole}-${jobId}`;
};

export const getLiveJobDetailPageSlug = ({ jobId, publicTitle = '' }: { jobId: string; publicTitle?: string }) => {
  const urlEncodedRole = slugify(publicTitle);
  return `${urlEncodedRole}-${jobId}`;
};

export const addQueryToURL = (url: string, query: string) => {
  if (!query) return url;
  const lastChar = url.slice(-1);
  if (lastChar === '/') return url.slice(0, -1) + query;
  return url + query;
};

export const getLocaleWithoutTheDefault = (locale: Locale) => {
  return locale === Locale.English ? '' : `/${locale}`;
};
