import { getLiveJobDetailPageSlug } from 'ui/utils/helpers/url-helper';

export const findContentByAbbreviation = (
  jobLanguageContent,
  langAbbreviation,
) => {
  return jobLanguageContent?.find(
    ({ abbreviation, publicDescription, publicTitle }) =>
      abbreviation?.toLowerCase() === langAbbreviation &&
      publicDescription &&
      publicTitle,
  );
};

export const getTranslatedSlug = (
  jobId,
  translatedContent = {},
  locale = null,
) => {
  const publicTitle = translatedContent?.publicTitle;
  return getLiveJobDetailPageSlug({
    jobId,
    publicTitle: publicTitle,
    locale,
  });
};
