import { useEffect, useRef, useState } from 'react';
import { debounce } from 'ui/utils/debounce';

export const useViewport = () => {
  const width = useRef(null);
  const [value, setValue] = useState('width=device-width,initial-scale=1');

  useEffect(() => {
    setViewportWidth();

    window.addEventListener('resize', setViewportWidth);
    return () => {
      window.removeEventListener('resize', setViewportWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setViewportWidth = debounce(() => {
    const lastWidthAbove360 = width.current > 360;
    const currentWidthAbove360 = window.outerWidth > 360;

    const isBreakpointChanged =
      !width.current || lastWidthAbove360 !== currentWidthAbove360;

    if (isBreakpointChanged) {
      const value =
        window.outerWidth > 360
          ? 'width=device-width,initial-scale=1'
          : 'width=360';
      setValue(value);
    }

    width.current = window.outerWidth;
  }, 100);

  return value;
};
