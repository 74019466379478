import { VideoModalButtonProps } from 'ui/components/VideoModalButton';

export interface HeadlineProps {
  title?: string | (() => string | JSX.Element) | JSX.Element;
  subTitle?: string;
  headlineColumnOneSize?: number;
  headlineSecondColumn?: string | JSX.Element;
  watchVideoBtn?: VideoModalButtonProps['watchVideoBtn'];
  tag?: HeadlineTag;
  size?: string;
  type?: string;
  className?: string;
  isSubtitleHTML?: boolean;
  subTitleClassName?: string;
  applySubtitleHTMLClassName?: boolean;
}

export enum HeadlineTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}
