import { ChangeEventHandler, FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import SearchIcon from 'ui/svgs/library/SearchIcon';
import CloseIcon from 'ui/svgs/library/CloseIcon';
import CloseCircleIcon from 'ui/svgs/library/CloseCircleIcon';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { Button } from '../Button';
import { CustomLink } from '../CustomLink';
import SearchResult from '../SearchResult';
import { SearchResponse } from '../../../../apps/web/service/elasticsearch';

type SearchBarProps = {
  defaultValue?: string;
  onSubmit?: (query: string) => void;
  getSuggestions?: (query: string) => Promise<string[]>;
  getQuickResults?: (query: string) => Promise<SearchResponse>;
}

export default function SearchBar({ defaultValue, onSubmit, getSuggestions, getQuickResults }: SearchBarProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue || '');
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState<SearchResponse['results']>();
  const [focused, setFocused] = useState(false);
  const [position, setPosition] = useState(-1);
  const [open, setOpen] = useState(false);
  const { t, lang } = useTranslation('search');
  const searchData: any = t('.', {}, { returnObjects: true });
  const router = useRouter();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const debounceResults = useMemo(() => {
    return debounce(handleChange, 250);
  }, []);

  useEffect(() => {
    return () => {
      debounceResults.cancel();
    };
  });

  useEffect(() => {
    if (value.length >= 3) {
      // getSuggestions?.(value).then(setSuggestions);
      getQuickResults(value).then((response) => setResults(response.results));

    } else {
      // setSuggestions([]);
      setResults([]);
    }
  }, [value, getSuggestions, getQuickResults]);

  useEffect(() => {
    setPosition(-1);
  }, [suggestions]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (e.currentTarget.q.value) {
      onSubmit?.(e.currentTarget.q.value);
      setOpen(false);
      setValue('');
    }
  };

  return (
    <>
      {!router.pathname.startsWith('/search') && (
        <Button
          className={clsx(
            "[ border-0 ][ w-8 h-8 ][ p-1 m-2 lg:m-0 ][ flex items-center justify-center ][ text-white ][ hover:bg-white/10 transition ]",
            {
              '[ bg-white/10 ]' : open, 
              '[ bg-transparent ]' : !open
            }
          )}
          onClick={() => setOpen(open => !open)}
        >
          <SearchIcon fill="#fff" />
        </Button>
      )}

      {open && (
        <div className="[ fixed top-[72px] ] left-0 z-[1000000001] ][ w-full h-full ]" onClick={() => setOpen(false)}>
          <div className="wide-container [ min-h-[calc(100vh-72px)] lg:min-h-96 ][ bg-gray-10 lg:bg-gray-0 backdrop-blur-md ]" onClick={(e) => e.stopPropagation()}>
            <div className="[ p-5 lg:px-20 ]">
              <Button className="[ ml-auto py-1 px-3 ][ bg-transparent ][ border-0 lg:border lg:border-solid lg:border-white ][ rounded-lg ][ flex items-center justify-center gap-3 ][ lg:w-12 lg:h-12 ][ hover:bg-white/10 transition ][ text-white  text-14 leading-none font-medium ]" onClick={() => {
                setOpen(false);
                setValue('')
              }}>
                <CloseIcon fill="#fff" />
                <span className="[ lg:sr-only ]">{searchData.close_button_text}</span>
              </Button>
            </div>

            <form className="[ px-5 py-11 lg:px-44 lg:pt-0 lg:pb-16 ]" onSubmit={handleSubmit}>
              <div className="[ border-b border-solid border-gray-30 ][ flex items-center ]">
                <input
                  ref={ref}
                  className="[ border-0 outline-0 ][ p-2 lg:px-3 ][ w-full ][ text-20 lg:text-28 text-white caret-white ][ bg-transparent ][ focus:placeholder:text-white placeholder:text-white/50 ]"
                  name="q"
                  autoFocus
                  defaultValue={defaultValue}
                  onChange={debounceResults}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  autoComplete="off"
                  placeholder={searchData.placeholder_text}
                  // onKeyDown={(e) => {
                  //   if (['Tab', 'ArrowUp', 'ArrowDown'].includes(e.key)) e.preventDefault();

                  //   if (suggestions.length === 0) return;

                  //   if (e.key === 'Tab') {
                  //     setValue(suggestions[position < 0 ? 0 : position]);
                  //     setPosition(-1);
                  //   } else if (e.key === 'Enter') {
                  //     setValue(position < 0 ? value : suggestions[position]);
                  //     onSubmit?.(position < 0 ? value : suggestions[position]);
                  //     setPosition(-1);
                  //     ref.current?.blur();
                  //   } else if (e.key === 'ArrowDown') {
                  //     setPosition((position + 1) % suggestions.length);
                  //   } else if (e.key === 'ArrowUp') {
                  //     setPosition((position - 1 < 0) ? suggestions.length - 1: position - 1);
                  //   } else if (e.key === 'Escape') {
                  //     ref.current?.blur();
                  //     setOpen(false);
                  //   }
                  // }}
                />

                <Button className="[ w-12 h-12 ][ p-3 ][ bg-transparent ][ hover:bg-white/10 transition ][ border-0 ][ rounded-lg ]" type="submit" onClick={() => {}}>
                  <SearchIcon fill="#fff" />
                </Button>

                <Button className="[ w-12 h-12 ][ p-3 ][ bg-transparent ][ hover:bg-white/10 transition ][ border-0 ][ rounded-lg ]" type="reset" onClick={() => { ref.current?.focus(); setValue(''); } }>
                  <CloseCircleIcon fill="#fff" />
                </Button>
              </div>

              {/* Quick Results */}
              {value.length >= 3 && results.length > 0 && (
                <div className="[ mt-8 md:mt-6 ][ divide-y divide-solid divide-gray-30 ]">
                  {results.map(result => (
                    <SearchResult key={result.id} result={result} variant="light" />
                  ))}
                </div>
              )}

              {/* Popular searches */}
              {!(value.length >= 3 && results.length > 0) && (
                <div className="[ mt-12 ]">
                  <div className="[ text-gray-60 text-14 font-medium ][ mb-5 ]">{searchData.popular_search_title}</div>
                  <div className="[ space-y-3 ]">
                    {searchData.popular_searches.map(({ search_term }, index) => (
                      <CustomLink href={`/search/?q=${encodeURIComponent(search_term)}`} key={index}>
                        <a className="[ text-20 lg:text-28 text-white ][ flex space-between items-center ][ rounded-lg ][ py-1 px-3 -mx-3 ][ hover:bg-white/10 transition ]" onClick={() => setOpen(false)}>
                          <div>{search_term}</div>
                          <ArrowForward />
                        </a>
                      </CustomLink>
                    ))}
                  </div>
                </div>
              )}

              {/* Suggestions */}
              {/* {false && focused && value.length >= 3 && !!suggestions.length && (
                <div className={styles.suggestions}>
                  {suggestions.map((suggestion, index) => (
                    <div
                      className={index === position ? styles.active : ''}
                      onClick={() => {
                        setValue(suggestion);
                        onSubmit?.(suggestion);
                        ref.current?.blur();
                      }}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )} */}
            </form>
          </div>
        </div>
      )}
    </>
  )
}
