import { ImageProps } from 'next/legacy/image';

export interface ResponsiveImageProps
  extends Omit<ImageProps, 'width' | 'src'> {
  alternativeText?: string;
  src?: string;
  layout?: ImageProps['layout'];
  blurHash?: string;
  width?: number;
  height?: number;
  formats?: {
    [key in ResponsiveImageFormat]?: ResponsiveImageFormatData;
  };
  rootMargin?: string;
}

export enum ResponsiveImageFormat {
  Small = 'small',
  Medium = 'medium',
}

export interface ResponsiveImageFormatData {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: number | null;
  url: string;
}
